export enum JobType {
  Region = 'Region',
  Point = 'Point',
  Score = 'Score',
  Evaluation = 'Evaluation',
  FrameCreation = 'Frame',
}

export enum WorkType {
  Slide = 'Slide',
  Frame = 'Frame',
  Batch = 'Batch',
}

export enum JobCategory {
  DataPreparation = 'DATA_PREPARATION',
  PrimaryAnnotation = 'PRIMARY_ANNOTATION',
  CorrectionAnnotation = 'CORRECTION_ANNOTATION',
  HeatmapEvaluation = 'HEATMAP_EVALUATION',
  ValidationAnnotation = 'EVALUATION_ANNOTATION',
  SlideScore = 'SLIDE_SCORE',
  ContributorQualification = 'CONTRIBUTOR_QUALIFICATION',
  FrameCreation = 'FRAME_CREATION',
  Other = 'OTHER',
}

// Names should probably be updated in the DB but might break things.
export enum SubstanceType {
  Tissue = 'Region',
  Cell = 'Point',
}

// Todo: remove this hard coded map
export const SubstanceTypeIdByName = {
  [SubstanceType.Tissue]: 1,
  [SubstanceType.Cell]: 2,
};

export const NEEDS_REVIEW_REASONS = [
  { acronym: 'NES', label: 'Not enough of requested substances' },
  { acronym: 'TEC', label: 'Technical issue' },
  { acronym: 'QUAL', label: 'Insufficient slide quality' },
  { acronym: 'MAP', label: 'Missing heatmap' },
  { acronym: 'WSO', label: 'Wrong stain or organ' },
  { acronym: 'DWTD', label: "I don't want to do this slide" },
  { acronym: 'FNNC', label: 'Frame does not need correction' },
];

// TODO: [PERMISSION_TO_GROUP] Remove permissions when groups are fully ready
export enum UserPermission {
  AssignUsersToTags = 'tagging.assign_users_tag',
  RemoveTagsFromUser = 'accounts.remove_tags_user',
  AddUser = 'accounts.add_user',
  AssignTagsToUser = 'accounts.assign_tags_user',
  ChangeTags = 'tagging.change_tag',
  AddTags = 'tagging.add_tag',
  DeleteTags = 'tagging.delete_tag',
  ChangeUser = 'accounts.change_user',
  ChangeProjectMembership = 'project_management.change_projectmembership',
  DownloadAnnotation = 'annotations.download_annotation',
  ViewAnnotation = 'annotations.view_annotation',
  AddAnnotation = 'annotations.add_annotation',
  OverlayUpload = 'overlays.create_runupload',
  OverlayViewUpload = 'overlays.view_detail_runupload',
  ChangeOverlay = 'overlays.change_run',
  EditJob = 'jobs.change_job',
  ViewJob = 'jobs.view_job',
  HideJob = 'jobs.hide_job',
  WorkOnJob = 'JobWork',
  ViewSubstance = 'substances.view_canonicalsubstance',
  EditSubstance = 'substances.change_canonicalsubstance',
  ViewPartner = 'partners.view_partner',
  AddPartner = 'partners.add_partner',
  AddDataset = 'datasets.add_dataset',
  ViewDataset = 'datasets.view_dataset',
  ViewRun = 'overlays.view_run',
  ViewOverlay = 'overlays.view_overlay',
  ViewUser = 'accounts.view_user',
  InviteAdmin = 'accounts.invite_admin',
  InvitePartner = 'accounts.invite_partner',
  InviteContributor = 'accounts.invite_contributor',
  ViewSlide = 'slides.view_slide',
  ViewFrame = 'slides.view_frame',
  ViewOverlayReview = 'overlays.view_overlaychannelreview',
  AddOverlayReview = 'overlays.add_overlaychannelreview',
  ViewProject = 'project_management.view_projectmanagementproject',
  EditProject = 'project_management.change_projectmanagementproject',
  ViewProjectMembership = 'project_management.view_projectmembership',
  ViewStructuredLogMessages = 'accounts.view_structuredlogmsg',
  ViewComment = 'collaboration.view_comment',
  AddComment = 'collaboration.add_comment',
  DownloadComment = 'collaboration.download_comment',
  ViewDataSource = 'slides.view_data_sources_slide',
  HideSlide = 'slides.hide_slide',
  ViewSlideAssignment = 'tasks.view_slideassignment',
  ViewMlModel = 'project_management.view_mlmodel',
  ViewCandidateRun = 'annotations.view_candidaterun',
}

export enum PermissionGroup {
  ManageSubstancesAndEvalQs = 'Manage Substances and Evaluation Questions',
  PartnerViewImgsAndData = 'Partner View Images and Related Data',
  ViewImgsAndData = 'View Images and Related Data',
  ViewPartners = 'View Partners',
  ManageDatasets = 'Manage Datasets',
  ViewDatasets = 'View Datasets',
  UploadDatasets = 'Upload Datasets',
  UploadRuns = 'Upload Runs',
  ManageContributorUsers = 'Manage Contributor Users',
  ManageContributorTagging = 'Manage Contributor Tagging',
  ManagePartnerUsers = 'Manage Partner Users',
  ManageInternalUsers = 'Manage Internal Users',
  ViewContributorUsers = 'View Contributor Users',
  ViewPartnerUsers = 'View Partner Users',
  ViewInternalUsers = 'View Internal Users',
  DownloadAnnotation = 'Download Annotations',
  ViewOverlayReview = 'View Overlay Reviews',
  ManageOverlayReview = 'Manage Overlay Reviews',
  TaskWorker = 'Partner Task Worker',
  ViewProjects = 'View Projects',
  ManageProjects = 'Manage Projects',
  ViewProjectMembership = 'View Project Membership',
  ManageProjectMembership = 'Manage Project Membership',
  JobWork = 'JobWork',
  ReviewAnnotations = 'Review Annotations',
  ViewReviewAnnotations = 'View Annotation Review',
  AuditLogins = 'Audit Logins',
  HideJobs = 'Hide Jobs',
  CommentSlides = 'Slide Commentator',
  CommentSlidesRO = 'CommentRO',
  CommentSlidesRW = 'CommentRW',
  ViewJobs = 'View Jobs',
  ViewJobsInternal = 'View Jobs [Internal]',
  ManageJobs = 'Manage Jobs',
  PayJob = 'Manage Job Payment',
  SearchAnnotations = 'Search Annotations',
  ViewStatistics = 'View Global Statistics',
  ManageOverlays = 'Manage Overlays',
  HideSlide = 'HideSlides',
  SearchOverlay = 'Search Overlay',
  ManageCandidateBatches = 'Manage Candidate Batches',
}

export enum PermissionRole {
  DefaultProjectMember = 'WIP Project Member',
}

// TODO: [PERMISSION_TO_GROUP] Remove permissions when groups are fully ready
export interface PermissionConfig {
  // For more information, refer to UserManager.hasPermissionGroup
  groups: PermissionGroup[]; // OR relation
  permissions: UserPermission[]; // OR relation
  permissionAnd?: boolean; // Flag to change the permissions to an AND relation
}

// TODO: [PERMISSION_TO_GROUP] Remove permissions when groups are fully ready.
// ^ Currently, permissions are added below because user may have permission but not the necessary group yet
export const PermissionConfigs = {
  Substances: {
    groups: [
      PermissionGroup.ManageSubstancesAndEvalQs,
      PermissionGroup.PartnerViewImgsAndData,
      PermissionGroup.ViewImgsAndData,
    ],
    permissions: [UserPermission.ViewSubstance],
  },
  SubstancesManage: {
    groups: [PermissionGroup.ManageSubstancesAndEvalQs],
    permissions: [UserPermission.EditSubstance],
  },
  Overlays: {
    groups: [
      PermissionGroup.ViewImgsAndData,
      PermissionGroup.PartnerViewImgsAndData,
      PermissionGroup.UploadRuns,
    ],
    permissions: [UserPermission.ViewRun, UserPermission.ViewOverlay],
  },
  SearchOverlay: {
    groups: [PermissionGroup.SearchOverlay],
    permissions: [UserPermission.ViewRun],
  },
  OverlaysManage: {
    groups: [PermissionGroup.UploadRuns, PermissionGroup.ManageOverlays],
    permissions: [UserPermission.ChangeOverlay],
  },
  OverlaysUpload: {
    groups: [PermissionGroup.UploadRuns],
    permissions: [UserPermission.OverlayUpload],
  },
  ViewOverlaysUpload: {
    groups: [],
    permissions: [UserPermission.OverlayViewUpload],
  },
  OverlayReviews: {
    groups: [
      PermissionGroup.ViewOverlayReview,
      PermissionGroup.ManageOverlayReview,
    ],
    permissions: [UserPermission.ViewOverlayReview],
  },
  Slides: {
    groups: [
      PermissionGroup.ViewImgsAndData,
      PermissionGroup.PartnerViewImgsAndData,
    ],
    permissions: [UserPermission.ViewSlide],
  },
  Frames: {
    groups: [
      PermissionGroup.ViewImgsAndData,
      PermissionGroup.PartnerViewImgsAndData,
    ],
    permissions: [UserPermission.ViewFrame],
  },
  Evaluations: {
    groups: [
      PermissionGroup.ManageSubstancesAndEvalQs,
      PermissionGroup.PartnerViewImgsAndData,
      PermissionGroup.ViewImgsAndData,
    ],
    permissions: [UserPermission.ViewSubstance],
  },
  EvaluationsManage: {
    groups: [PermissionGroup.ManageSubstancesAndEvalQs],
    permissions: [UserPermission.EditSubstance],
  },
  Datasets: {
    groups: [PermissionGroup.ViewDatasets],
    permissions: [UserPermission.ViewDataset],
  },
  DatasetsManage: {
    groups: [PermissionGroup.ManageDatasets],
    permissions: [UserPermission.AddDataset],
  },
  UploadDatasets: {
    groups: [PermissionGroup.UploadDatasets],
    permissions: [UserPermission.AddDataset],
  },
  Partners: {
    groups: [
      PermissionGroup.ManagePartnerUsers,
      PermissionGroup.ViewPartnerUsers,
      PermissionGroup.ViewPartners,
    ],
    permissions: [UserPermission.ViewPartner],
  },
  PartnersManage: {
    groups: [PermissionGroup.ManagePartnerUsers],
    permissions: [UserPermission.AddPartner],
  },
  Users: {
    groups: [
      PermissionGroup.ManagePartnerUsers,
      PermissionGroup.ManageContributorUsers,
      PermissionGroup.ManageInternalUsers,
      PermissionGroup.ViewPartnerUsers,
      PermissionGroup.ViewContributorUsers,
      PermissionGroup.ViewInternalUsers,
    ],
    permissions: [],
  },
  UsersManage: {
    groups: [
      PermissionGroup.ManagePartnerUsers,
      PermissionGroup.ManageContributorUsers,
      PermissionGroup.ManageInternalUsers,
    ],
    permissions: [
      UserPermission.InvitePartner,
      UserPermission.InviteContributor,
      UserPermission.InviteAdmin,
    ],
  },
  UserContributors: {
    groups: [
      PermissionGroup.ManageContributorUsers,
      PermissionGroup.ViewContributorUsers,
    ],
    permissions: [],
  },
  ManageContributorUsers: {
    groups: [PermissionGroup.ManageContributorUsers],
    permissions: [UserPermission.AssignUsersToTags],
  },
  // Should probably be separate at least on the meaning of: feature flags are the same things
  // as tags... which is awkward...
  UserTagging: {
    groups: [
      PermissionGroup.ManageContributorUsers,
      PermissionGroup.ManageContributorTagging,
    ],
    permissions: [
      UserPermission.AddTags,
      UserPermission.ChangeTags,
      UserPermission.AssignUsersToTags,
      UserPermission.AssignTagsToUser,
      UserPermission.RemoveTagsFromUser,
      UserPermission.DeleteTags,
    ],
  },
  Annotations: {
    groups: [
      PermissionGroup.SearchAnnotations,
      PermissionGroup.DownloadAnnotation,
    ],
    permissions: [UserPermission.ViewAnnotation],
  },
  AnnotationDownload: {
    groups: [PermissionGroup.DownloadAnnotation],
    permissions: [UserPermission.DownloadAnnotation],
  },
  Projects: {
    groups: [PermissionGroup.ViewProjects, PermissionGroup.ManageProjects],
    permissions: [UserPermission.ViewProject],
  },
  ProjectsManage: {
    groups: [PermissionGroup.ManageProjects],
    permissions: [UserPermission.EditProject],
  },
  ProjectMemberships: {
    groups: [
      // Only compatible with the new project page; not yet safe to enable.
      // PermissionGroup.ViewProjectMembership,
      PermissionGroup.ManageProjectMembership,
      PermissionGroup.ManageProjects,
    ],
    permissions: [UserPermission.ViewProjectMembership],
  },
  ProjectMembershipsManage: {
    groups: [PermissionGroup.ManageProjectMembership],
    permissions: [UserPermission.ChangeProjectMembership],
  },
  // Same as ProjectsManage for now, but put in to possibly distinguish between editing results within a project from
  // editing & adding projects as a whole - which could be broken out in the future.
  ProjectResultsManage: {
    groups: [PermissionGroup.ManageProjects],
    permissions: [UserPermission.EditProject],
  },
  JobWork: {
    groups: [PermissionGroup.JobWork],
    permissions: [],
  },
  AnnotationsReview: {
    groups: [
      PermissionGroup.ReviewAnnotations,
      PermissionGroup.ViewReviewAnnotations,
    ],
    permissions: [],
  },
  // This is intended as a superuser workflow; there isn't a particular group associated to it.
  RecentLogins: {
    groups: [PermissionGroup.AuditLogins],
    permissions: [UserPermission.ViewStructuredLogMessages],
  },
  Statistics: {
    groups: [PermissionGroup.ViewStatistics],
    permissions: [],
  },
  HideJob: {
    groups: [PermissionGroup.HideJobs],
    permissions: [UserPermission.HideJob],
  },
  SlideComments: {
    groups: [
      PermissionGroup.CommentSlidesRO,
      PermissionGroup.CommentSlidesRW,
      PermissionGroup.CommentSlides,
    ],
    permissions: [UserPermission.ViewComment, UserPermission.AddComment],
  },
  SlideCommentsManage: {
    groups: [PermissionGroup.CommentSlidesRW, PermissionGroup.CommentSlides],
    permissions: [UserPermission.AddComment, UserPermission.DownloadComment],
  },
  ViewJob: {
    groups: [
      PermissionGroup.ViewJobs,
      PermissionGroup.ViewJobsInternal,
      PermissionGroup.ManageJobs,
      PermissionGroup.PayJob,
    ],
    permissions: [UserPermission.ViewJob],
  },
  ManageJob: {
    groups: [PermissionGroup.ManageJobs, PermissionGroup.PayJob],
    permissions: [UserPermission.EditJob],
  },
  PayJob: {
    groups: [PermissionGroup.PayJob],
    permissions: [],
  },
  // These two groups gate access to the various places where one can view a user's permissions.
  // Note that a user might be allowed to view the users themselves (e.g. for assignment to a job)
  // without being allowed information about their permissions.
  ManageUserPermissions: {
    groups: [
      PermissionGroup.ManageInternalUsers,
      PermissionGroup.ManagePartnerUsers,
    ],
    permissions: [UserPermission.ChangeProjectMembership],
  },
  ViewUserPermissions: {
    groups: [
      PermissionGroup.ManageInternalUsers,
      PermissionGroup.ManagePartnerUsers,
      PermissionGroup.ViewPartnerUsers,
      PermissionGroup.ViewInternalUsers,
    ],
    permissions: [
      UserPermission.ViewProjectMembership,
      UserPermission.ChangeProjectMembership,
    ],
  },
  HideSlide: {
    groups: [PermissionGroup.HideSlide],
    permissions: [UserPermission.HideSlide],
  },
  // These three configurations control small features of a few pages, which vary a bit mostly on whether the user is an admin
  // or a partner, where there are some similar but not equivalent permission groups. They may really be modelled by permissions,
  // whereas others should be thought of more as workflows represented by a group.
  ViewDataSource: {
    groups: [],
    permissions: [UserPermission.ViewDataSource],
  },
  ViewSlideAssignment: {
    groups: [],
    permissions: [UserPermission.ViewSlideAssignment],
  },
  MlModels: {
    groups: [],
    permissions: [UserPermission.ViewMlModel],
  },
  CandidateRuns: {
    groups: [],
    permissions: [UserPermission.ViewCandidateRun],
  },
  ManageCandidateRuns: {
    groups: [PermissionGroup.ManageCandidateBatches],
    permissions: [],
  },
};

export enum AnnotationCategorization {
  GOOD = 'GOOD',
  BAD = 'BAD',
  REVIEWED = 'REVIEWED',
  BULK_BAD = 'BULK_BAD',
  CREATED = 'CREATED',
}

export const STATUS_LIST = [
  {
    status: AnnotationCategorization.REVIEWED,
    label: 'Reviewed',
  },
  {
    status: AnnotationCategorization.GOOD,
    label: 'Good',
  },
  {
    status: AnnotationCategorization.BAD,
    label: 'Bad',
  },
  {
    status: AnnotationCategorization.BULK_BAD,
    label: 'Bulk Bad',
  },
  {
    status: AnnotationCategorization.CREATED,
    label: 'Unreviewed',
  },
];
