import type {
  AxiosRequestConfig,
  AxiosRequestHeaders,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from 'axios';
import axios from 'axios';
import deepMapKeys from 'deep-map-keys';
import { camelCase, cloneDeep, snakeCase } from 'lodash';
import qs from 'qs';

import { authentication } from '@/api-connect/routes';
import { useToaster } from '@/ui-library/components/Toaster/store';

export const paramsSerializer = (params: unknown): string =>
  qs.stringify(params, { arrayFormat: 'repeat' });

export const requestToSnakeCase = <D>(
  request: InternalAxiosRequestConfig<D>
): InternalAxiosRequestConfig<D> => {
  // Defaults to true when not specified
  const shouldSnakeCase = request?.headers?.shouldSnakeCase ?? true;

  // Remove the shouldSnakeCase header from the request
  const { shouldSnakeCase: _, ...headers } = request?.headers ?? {};
  request.headers = headers as AxiosRequestHeaders;

  // Conditionally snake case the params and data
  if (shouldSnakeCase) {
    request.params = deepMapKeys(request.params, snakeCase);
    request.data = deepMapKeys(request.data, snakeCase);
  }

  return request;
};

export const responseToCamelCase = <
  T extends { data: any; originalData?: any },
>(
  response: AxiosResponse
): AxiosResponse<T, any> => {
  if (response.status !== 204) {
    response.data.originalData = cloneDeep(response.data.data);
    response.data.data = deepMapKeys(response.data.data, camelCase);
  }
  return response;
};

export const snakeCaseRequestInterceptor = <D>(
  request: InternalAxiosRequestConfig<D>
): InternalAxiosRequestConfig<D> => {
  if (request.url && /slides\/v2/i.test(request.url)) {
    request = requestToSnakeCase(request);
  }
  return request;
};

const expected401ErrorPaths = [
  '/slides/v2/user/',
  '/slides/v2/permissions/',
  '/authenticate',
];
// This interceptor is used to handle 401 errors. It will display a toast message and provide a button to redirect the user to the login page.
export const unauthenticatedResponseInterceptor = (error: any): any => {
  const { addToastMessage } = useToaster();

  if (
    error?.response?.status === 401 &&
    !expected401ErrorPaths.some(path =>
      `${error.response.config?.baseURL}${error.response.config?.url}`.includes(
        path
      )
    )
  ) {
    addToastMessage({
      text: 'Unauthorized, please log in again.',
      type: 'error',
      timeoutMs: 0,
      actionCallback: () => {
        window.location.href = '/logout-slides';
      },
      actionText: 'Login',
      contextId: 'unauthorized-interceptor',
    });
  }

  return Promise.reject(error);
};

// For some accounts endpoints, we need to send a CSRF token in the headers
export const accountsCSRFRequestInterceptor = async <D>(
  request: InternalAxiosRequestConfig<D>
): Promise<InternalAxiosRequestConfig<D>> => {
  // All of these endpoints are reverse proxied to the accounts app
  const CSRF_REQUIRED_ENDPOINTS = [
    '/invitationinfo/',
    '/samllogin',
    '/authenticate',
    '/requestreset',
    '/setPassword',
    '/submitRegistration',
  ];
  if (
    request.url &&
    CSRF_REQUIRED_ENDPOINTS.some(endpoint => request.url?.startsWith(endpoint))
  ) {
    const csrfResponse = await axios.get<string>(authentication.getCSRFToken());
    request.headers = {
      ...request.headers,
      'Csrf-Token': csrfResponse.data,
    } as unknown as AxiosRequestHeaders;
  }
  return request;
};

export const snakeCaseResponseInterceptor = <
  T extends { data: any; originalData?: any },
>(
  response: AxiosResponse
): AxiosResponse<T, any> => {
  const { url } = response.config;
  if (url && /slides\/v2/i.test(url) && !/slides\/v2\/_urls/i.test(url)) {
    response = responseToCamelCase(response);
  }
  return response;
};

export const serializedAxios = axios.create({ paramsSerializer });
serializedAxios.interceptors.request.use(snakeCaseRequestInterceptor);
serializedAxios.interceptors.request.use(accountsCSRFRequestInterceptor);
serializedAxios.interceptors.response.use(snakeCaseResponseInterceptor);
serializedAxios.interceptors.response.use(
  r => r,
  unauthenticatedResponseInterceptor
);

export const encodeFormUrlComponent = (
  str: string | number | boolean
): string => encodeURIComponent(str).replace(/%20/g, '+').replace(/%2F/g, '/');

export default {
  get<T = any, R = AxiosResponse<T>, D = any>(
    url: string,
    options?: AxiosRequestConfig
  ): Promise<R> {
    return serializedAxios.get<T, R, D>(url, options);
  },
  head<T = any, R = AxiosResponse<T>, D = any>(
    url: string,
    options?: AxiosRequestConfig
  ): Promise<R> {
    return serializedAxios.head<T, R, D>(url, options);
  },
  delete<T = any, R = AxiosResponse<T>, D = any>(
    url: string,
    options?: AxiosRequestConfig
  ): Promise<R> {
    return serializedAxios.delete<T, R, D>(url, options);
  },
  post<T = any, R = AxiosResponse<T>, D = any>(
    url: string,
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    body?: any,
    options?: AxiosRequestConfig
  ): Promise<R> {
    return serializedAxios.post<T, R, D>(url, body, options);
  },
  put<T = any, R = AxiosResponse<T>, D = any>(
    url: string,
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    body?: any,
    options?: AxiosRequestConfig
  ): Promise<R> {
    return serializedAxios.put<T, R, D>(url, body, options);
  },
  patch<T = any, R = AxiosResponse<T>, D = any>(
    url: string,
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    body?: any,
    options?: AxiosRequestConfig
  ): Promise<R> {
    return serializedAxios.patch<T, R, D>(url, body, options);
  },
  request<T = any, R = AxiosResponse<T>, D = any>(
    options: AxiosRequestConfig
  ): Promise<R> {
    return serializedAxios.request<T, R, D>(options);
  },
};
