import type { AxiosRequestConfig } from 'axios';
import axios from 'axios';
import { camelCase } from 'lodash';

import {
  paramsSerializer,
  requestToSnakeCase,
  responseToCamelCase,
  unauthenticatedResponseInterceptor,
} from '@/ui-library/utils/http';
import { getAuthHeader } from '@/ui-library/utils/session';
import type { Dict } from '@/utils/types';

// There may be a better way to this. Probably should get moved to a single util that is typed
type MetaCSRF = Element & { content: string };
const metaCSRF = document.head.querySelector('[name=csrf-token]') as
  | MetaCSRF
  | undefined;

// This value is being set by slidesapp in slidesapp/sites/slides.pathai.com/slidesapp/app/views/layouts/main.scala.html
declare global {
  interface Window {
    CSRF_TOKEN?: string;
  }
}
export const CSRF_TOKEN = window.CSRF_TOKEN || metaCSRF?.content || 'csrf';

// Monocle doesn't support requests without a limit at the moment, so for
// places where correctness requires all items to be loaded pass one of these
// as the limit. A better solution is tracked in CR-81.
export const BIG_LIMIT = 2222;
export const BIGGER_LIMIT = 5555;
export const DEFAULT_LIMIT = 100;

export const defaultHeaders = {
  Authorization: getAuthHeader(),
  'Csrf-Token': CSRF_TOKEN,
  'Cache-Control': 'no-cache, max-age=0, must-revalidate, no-store',
  'Content-Type': 'application/json',
};

export const MONOCLE_BASE_PATH = '/slides/v2';
export const monocle = axios.create({
  baseURL: MONOCLE_BASE_PATH,
  headers: defaultHeaders,
  paramsSerializer,
});

monocle.interceptors.request.use(requestToSnakeCase);
monocle.interceptors.response.use(responseToCamelCase);
monocle.interceptors.response.use(r => r, unauthenticatedResponseInterceptor);

type UrlArgs = Dict<string>;

/**
 * Fills in the arguments of a URL pattern.
 *
 * @param url - The URL patten, e.g. `/jobs/<pk>/`.
 * @param args - The args to fill into the URL, e.g. `{ pk: '1' }`.
 * @returns The resolved URL, e.g. `/slides/v2/jobs/1/`.
 */
export const fillArgs = (url: string, args?: UrlArgs): string => {
  url = url.replace('{', '<').replace('}', '>');
  const tokens = url.match(/<\w*>/g);
  if (!args) {
    if (tokens) {
      throw new Error(`Arguments ${tokens} not provided to ${url}`);
    }
    return url;
  }
  if (typeof args == 'object') {
    if (tokens) {
      tokens.forEach(token => {
        const argName = camelCase(token.slice(1, -1));
        const argVal = args[argName];
        if (argVal === undefined) {
          throw new Error(`Argument ${argName} not provided to ${url}`);
        }
        url = url.replace(token, argVal);
      });
    }
  }
  return url;
};

interface MonocleGet {
  urlArgs?: UrlArgs;
  params?: AxiosRequestConfig['params'];
  headers?: AxiosRequestConfig['headers'];
  signal?: AxiosRequestConfig['signal'];
}
/**
 * When provided with a monocle endpoint name,
 * this function will return a function that takes an object `{ urlArgs, params }` as arguments.
 *
 * Depending on the documentation for the aforementioned monocle endpoint,
 * `urlArgs` or `params` may require additional attributes.
 *
 * If the monocle endpoint has url arguments,
 * then they must be provided to `urlArgs` in camelCase.
 *
 * If the monocle endpoint takes query parameters,
 * they should be provided to `params`, again in camelCase.
 *
 * @param {string} url - The endpoint defined in monocle.
 * @return {function} A function that will make a GET request when called.
 *
 * @example
 * const fetchJob = monocleGet('/jobs/jobs/<pk>/');
 * fetchJob({ urlArgs: { jobId: 123 } });
 */
export const monocleGet =
  <T = any>(url: string) =>
  ({ urlArgs, params, headers, signal }: MonocleGet = {}) =>
    monocle.get<T>(fillArgs(url, urlArgs), { params, headers, signal });

interface MonocleHead {
  urlArgs?: UrlArgs;
  headers?: AxiosRequestConfig['headers'];
}
/**
 * When provided with a monocle endpoint url,
 * this function will return a function that takes an object `{ urlArgs }` as arguments.
 *
 * Depending on the documentation for the aforementioned monocle endpoint,
 * `urlArgs` may require additional attributes.
 *
 * If the monocle endpoint has url arguments,
 * then they must be provided to `urlArgs` in camelCase.
 *
 * NOTE: Currently, there are no instances of head requests being made from path-ui.
 *
 * @param {string} url - The endpoint defined in monocle.
 * @return {function} A function that will make a HEAD request when called.
 *
 * @example
 * const jobHead = monocleHead('/jobs/jobs/<pk>/');
 * jobHead({ urlArgs: { jobId: 123 } });
 */
export const monocleHead =
  <T = any>(url: string) =>
  ({ urlArgs, headers }: MonocleHead = {}) =>
    monocle.head<T>(fillArgs(url, urlArgs), { headers });

type MonocleDelete = MonocleHead;
/**
 * When provided with a monocle endpoint name, this function will
 * return a function that takes an object `{ urlArgs }` as arguments.
 *
 * Depending on the documentation for the aforementioned monocle endpoint,
 * `urlArgs` may require additional attributes.
 *
 * If the monocle endpoint has url arguments,
 * then they must be provided to `urlArgs` in camelCase.
 *
 * @param {string} name - The endpoint defined in monocle.
 * @return {function} A function that will make a DELETE request when called.
 *
 * @example
 * const deleteJob = monocleDelete('/jobs/jobs/<pk>/');
 * deleteJob({ urlArgs: { jobId: 123 } });
 */
export const monocleDelete =
  <T = any>(url: string) =>
  ({ urlArgs, headers }: MonocleDelete = {}) =>
    monocle.delete<T>(fillArgs(url, urlArgs), { headers });

interface MonoclePost {
  urlArgs?: UrlArgs;
  params?: AxiosRequestConfig['params'];
  headers?: AxiosRequestConfig['headers'];
}
/**
 * When provided with a monocle endpoint name,
 * this function will return a function that takes an object `{ urlArgs, params }` as arguments.
 *
 * Depending on the documentation for the aforementioned monocle endpoint,
 * `urlArgs` or `params` may require additional attributes.
 *
 * If the monocle endpoint has url arguments,
 * then they must be provided to `urlArgs` in camelCase.
 *
 * If the monocle endpoint takes query parameters,
 * they should be provided to `params`, again in camelCase.
 *
 * @param {string} name - The endpoint defined in monocle.
 * @return {function} A function that will make a POST request when called.
 *
 * @example
 * const postJob = monoclePost('/jobs/jobs/<pk>/');
 * postJob({ params: { name: 'test' } });
 */
export const monoclePost =
  <T = any>(url: string) =>
  ({ urlArgs, params, headers }: MonoclePost = {}) =>
    monocle.post<T>(fillArgs(url, urlArgs), params, { headers });

type MonoclePut = MonoclePost;
/**
 * When provided with a monocle endpoint name,
 * this function will return a function that takes an object `{ urlArgs, params }` as arguments.
 *
 * Depending on the documentation for the aforementioned monocle endpoint,
 * `urlArgs` or `params` may require additional attributes.
 *
 * If the monocle endpoint has url arguments,
 * then they must be provided to `urlArgs` in camelCase.
 *
 * If the monocle endpoint takes query parameters,
 * they should be provided to `params`, again in camelCase.
 *
 * @param {string} name - The endpoint defined in monocle.
 * @return {function} A function that will make a PUT request when called.
 *
 * @example
 * const putJob = monoclePut('/jobs/jobs/<pk>/');
 * putJob({ urlArgs: { jobId: 123 }, params: { partner: 4} });
 */
export const monoclePut =
  <T = any>(url: string) =>
  ({ urlArgs, params, headers }: MonoclePut = {}) =>
    monocle.put<T>(fillArgs(url, urlArgs), params, { headers });

type MonoclePatch = MonoclePut;
/**
 * When provided with a monocle endpoint name,
 * this function will return a function that takes an object `{ urlArgs, params }` as arguments.
 *
 * Depending on the documentation for the aforementioned monocle endpoint,
 * `urlArgs` or `params` may require additional attributes.
 *
 * If the monocle endpoint has url arguments,
 * then they must be provided to `urlArgs` in camelCase.
 *
 * If the monocle endpoint takes query parameters,
 * they should be provided to `params`, again in camelCase.
 *
 * @param {string} name - The endpoint defined in monocle.
 * @return {function} A function that will make a PATCH request when called.
 *
 * @example
 * const patchJob = monoclePatch('/jobs/jobs/<pk>/');
 * patchJob({ urlArgs: { jobId: 123 }, params: { partner: 4} });
 */
export const monoclePatch =
  <T = any>(url: string) =>
  ({ urlArgs, params, headers }: MonoclePatch = {}) =>
    monocle.patch<T>(fillArgs(url, urlArgs), params, { headers });
