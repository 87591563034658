export function loadScript(src: string): Promise<unknown> {
  return new Promise(resolve => {
    const script = document.createElement('script');
    script.onload = resolve;
    script.type = 'text/javascript';
    script.async = true;
    script.src = src;
    document.head.appendChild(script);
  });
}
