import { isDev } from '@/ui-library/utils/env';
import { loadScript } from '@/ui-library/utils/scripts';

const BEACON_ID = 'eaf7bf5c-73f6-46c0-a263-6efda2b7b1cd';
const BEACON_DEBUG = localStorage.getItem('BEACON_DEBUG');

const exportItems = {
  initBeacon,
  loadBeacon,
  BEACON_DEBUG,
};

// Script provided by HelpScout Beacon configuration
function loadBeacon(
  window: Window,
  document: Document,
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  b: any
): Promise<unknown> | undefined {
  const __loadScript = (): Promise<unknown> =>
    loadScript('https://beacon-v2.helpscout.net');

  if (
    // eslint-disable-next-line no-multi-assign
    ((window.Beacon = b =
      function (method: any, options: any, data: any) {
        window.Beacon.readyQueue.push({
          method,
          options,
          data,
        });
      }),
    (b.readyQueue = []),
    document.readyState === 'complete')
  ) {
    return __loadScript();
  }

  // @ts-expect-error - this is used to handle very old browsers. Its likely it can be revmoved
  if (window.attachEvent) {
    // @ts-expect-error - same
    window.attachEvent('onload', __loadScript);
  } else {
    window.addEventListener('load', __loadScript, false);
  }
}

function initBeacon(): void {
  // Using values in exportItems so these can be mocked in tests
  if (!isDev() || exportItems.BEACON_DEBUG) {
    exportItems.loadBeacon(window, document, window.Beacon || (() => {}));
    window.Beacon('init', BEACON_ID);
    // When the HelpScout Beacon closes refocus on the window, otherwise the
    // Beacon will continue to listen for keypress events and act on them if
    // needed.  For example, it watches for the space bar and reopens itself
    // when that key is pressed.  This causes a problem on the slide viewer,
    // since we use the space bar in DRAW mode with freehand annotations
    // to enable dragging.
    window.Beacon('on', 'close', () => {
      window.focus();
    });
  }
}

/* Adapt to a fluent interface

    // Original API
    Beacon('prefill', {...});
    Beacon('navigate', '/ask/');
    Beacon('open');

    // Fluent API
    HelpWidget.prefill({...})
      .navigate('/ask/')
      .open();
*/
export const HelpWidget: any = new Proxy(
  {},
  {
    get(_, prop, thisProxy) {
      if (prop === 'sessionData') {
        prop = 'session-data';
      } else if (prop === 'showMessage') {
        prop = 'show-message';
      }
      return (...args: any[]) => {
        window.Beacon(prop, ...args);
        return thisProxy;
      };
    },
  }
);

declare global {
  interface Window {
    Beacon?: any;
  }
}

export default exportItems;
