/**
 * Defines wrappers and helpers for vue-restful functions specific to
 * interactions with Monocle.
 */

import type { SnakeCasedPropertiesDeep } from 'type-fest';
import type { Ref } from 'vue';
import { computed } from 'vue';
import type {
  DetailApi,
  DetailApiOptions,
  EndpointApi,
  EndpointApiOptions,
  IdArg,
  InfiniteScrollApi,
  InfiniteScrollApiOptions,
  ListApi,
  ListApiOptions,
  ParamsArg,
  RecursivePartial,
  UrlArg,
} from 'vue-restful';
import {
  useDetailApi,
  useEndpointApi,
  useInfiniteScrollApi,
  useListApi,
} from 'vue-restful';

import type {
  InfiniteScrollCursorApi,
  InfiniteScrollCursorApiOptions,
} from '@/api/composables';
import { useInfiniteScrollCursorApi } from '@/api/composables';
import { monocle } from '@/ui-library/utils/monocleHttp';
import type { Dict, Id, Maybe } from '@/utils/types';

export interface MonocleEndpointData<T> {
  data: T;
  originalData: SnakeCasedPropertiesDeep<T>;
}

export interface MonocleDetailData<T> {
  data: T;
  originalData: SnakeCasedPropertiesDeep<T>;
}

export interface MonocleListData<T> {
  data: T[];
  originalData: Array<SnakeCasedPropertiesDeep<T>>;
  pagination: {
    count: number;
  };
}

export interface MonocleInfiniteScrollData<T> {
  data: T[];
  originalData: Array<SnakeCasedPropertiesDeep<T>>;
  pagination: {
    count: number;
    cursor?: string;
  };
}

// Generic added for easier flexibility
export interface MonocleSelectData<T extends Id = Id> {
  value: T;
  label: string;
}
export const getSelectId = <T extends Id = Id>(x: MonocleSelectData<T>): T =>
  x.value;

export type MonocleEnumPair = [value: string, label: string];
type MonocleEnumData = MonocleEndpointData<MonocleEnumPair[]>;

const extractDetailData = <T>(responseData: MonocleDetailData<T>): T =>
  responseData.data;
const extractListData = <T>(responseData: MonocleListData<T>): T[] =>
  responseData.data;
const extractListCount = <T>(responseData: MonocleListData<T>): number =>
  responseData.pagination.count;
const extractListCursor = <T>(
  responseData: MonocleInfiniteScrollData<T>
): Maybe<string> => responseData.pagination.cursor || null;
const extractEnumData = (responseData: MonocleEnumData): MonocleEnumPair[] =>
  responseData.data;
const extractEnumCount = (responseData: MonocleEnumData): number =>
  responseData.data.length;

export function useMonocleEndpoint<
  T,
  Res = MonocleEndpointData<T>,
  Req = RecursivePartial<T>,
>(url: UrlArg, options?: EndpointApiOptions<T, Res>): EndpointApi<T, Req> {
  return useEndpointApi<T, Res, Req>(url, {
    axiosInstance: monocle,
    // @ts-expect-error if Res is a different type the user must override this
    extractResponseData: extractDetailData,
    ...options,
  });
}

export function useMonocleDetail<
  T,
  Res = MonocleDetailData<T>,
  Req = RecursivePartial<T>,
>(
  url: UrlArg,
  id: IdArg,
  options?: DetailApiOptions<T, Res>
): DetailApi<T, Req> {
  return useDetailApi<T, Res, Req>(url, id, {
    axiosInstance: monocle,
    // @ts-expect-error if Res is a different type the user must override this
    extractResponseData: extractDetailData,
    ...options,
  });
}

export function useMonocleList<
  T,
  Res = MonocleListData<T>,
  Req = RecursivePartial<T>,
>(url: UrlArg, options?: ListApiOptions<T, Res>): ListApi<T, Req> {
  return useListApi<T, Res, Req>(url, {
    axiosInstance: monocle,
    // @ts-expect-error if Res is a different type the user must override this
    extractResponseData: extractListData,
    // @ts-expect-error if Res is a different type the user must override this
    extractResponseCount: extractListCount,
    ...options,
  });
}

export interface EnumApi extends ListApi<MonocleEnumPair> {
  // Convenience mapping of value to label.
  labelByValue: Ref<Dict<string>>;
}

/**
 * Helper function for Monocle enum endpoints that return a list of
 * (value, label) pairs.
 */
export function useMonocleEnum(
  url: UrlArg,
  options?: ListApiOptions<MonocleEnumPair, MonocleEnumData>
): EnumApi {
  const api = useListApi(url, {
    axiosInstance: monocle,
    getId: x => x[0],
    extractResponseData: extractEnumData,
    extractResponseCount: extractEnumCount,
    ...options,
  });
  return {
    ...api,
    labelByValue: computed(() =>
      api.data.value.reduce((acc: Dict<string>, [value, label]) => {
        acc[value] = label;
        return acc;
      }, {})
    ),
  };
}

export function useMonocleInfiniteScroll<
  T,
  Res = MonocleListData<T>,
  Req = RecursivePartial<T>,
>(
  url: UrlArg,
  options?: InfiniteScrollApiOptions<T, Res>
): InfiniteScrollApi<T, Req> {
  return useInfiniteScrollApi<T, Res, Req>(url, {
    axiosInstance: monocle,
    // @ts-expect-error if Res is a different type the user must override this
    extractResponseData: extractListData,
    // @ts-expect-error if Res is a different type the user must override this
    extractResponseCount: extractListCount,
    ...options,
  });
}

export function useMonocleInfiniteScrollCursor<
  T,
  Res = InfiniteScrollCursorApi<T>,
  Req = RecursivePartial<T>,
>(
  url: UrlArg,
  options?: InfiniteScrollCursorApiOptions<T, Res>
): InfiniteScrollCursorApi<T, Req> {
  return useInfiniteScrollCursorApi<T, Res, Req>(url, {
    axiosInstance: monocle,
    // @ts-expect-error if Res is a different type the user must override this
    extractResponseData: extractListData,
    // @ts-expect-error if Res is a different type the user must override this
    extractResponseCount: extractListCount,
    // @ts-expect-error if Res is a different type the user must override this
    extractResponseCursor: extractListCursor,
    ...options,
  });
}

// Factory versions of the above functions to bind URLs and fixed options like
// getId and produce endpoint functions with consistent interfaces.

export function useMonocleEndpointFactory<
  T,
  Res = MonocleEndpointData<T>,
  Req = RecursivePartial<T>,
>(url: UrlArg, options?: EndpointApiOptions<T, Res>) {
  return (optionsOverride?: EndpointApiOptions<T, Res>): EndpointApi<T, Req> =>
    useMonocleEndpoint(url, { ...options, ...optionsOverride });
}

export function useMonocleDetailFactory<
  T,
  Res = MonocleDetailData<T>,
  Req = RecursivePartial<T>,
>(url: UrlArg, options?: DetailApiOptions<T, Res>) {
  return (
    id: IdArg,
    optionsOverride?: DetailApiOptions<T, Res>
  ): DetailApi<T, Req> =>
    useMonocleDetail(url, id, { ...options, ...optionsOverride });
}

export function useMonocleListFactory<
  T,
  Res = MonocleListData<T>,
  Req = RecursivePartial<T>,
>(url: UrlArg, options?: ListApiOptions<T, Res>) {
  return (
    params?: ParamsArg,
    optionsOverride?: ListApiOptions<T, Res>
  ): ListApi<T, Req> =>
    useMonocleList(url, {
      ...options,
      ...optionsOverride,
      params,
    });
}

export function useMonocleSelectListFactory<T extends Id = number>(
  url: UrlArg,
  options?: ListApiOptions<
    MonocleSelectData<T>,
    MonocleListData<MonocleSelectData<T>>
  >
) {
  return useMonocleListFactory(url, {
    getId: getSelectId,
    extractResponseCount: x => x.data.length,
    ...options,
  });
}

export function useMonocleEnumFactory(
  url: UrlArg,
  options?: ListApiOptions<MonocleEnumPair, MonocleEnumData>
) {
  return (
    params?: ParamsArg,
    optionsOverride?: ListApiOptions<MonocleEnumPair, MonocleEnumData>
  ): EnumApi =>
    useMonocleEnum(url, {
      ...options,
      ...optionsOverride,
      params,
    });
}

export function useMonocleInfiniteScrollFactory<
  T,
  Res = MonocleListData<T>,
  Req = RecursivePartial<T>,
>(url: UrlArg, options?: InfiniteScrollApiOptions<T, Res>) {
  return (
    params?: ParamsArg,
    optionsOverride?: InfiniteScrollApiOptions<T, Res>
  ): InfiniteScrollApi<T, Req> =>
    useMonocleInfiniteScroll(url, {
      ...options,
      ...optionsOverride,
      params,
    });
}

export function useMonocleInfiniteScrollCursorFactory<
  T,
  Res = MonocleInfiniteScrollData<T>,
  Req = RecursivePartial<T>,
>(url: UrlArg, options?: InfiniteScrollCursorApiOptions<T, Res>) {
  return (
    params?: ParamsArg,
    optionsOverride?: InfiniteScrollCursorApiOptions<T, Res>
  ): InfiniteScrollCursorApi<T, Req> =>
    useMonocleInfiniteScrollCursor(url, {
      ...options,
      ...optionsOverride,
      params,
    });
}
