import type { RouterOptions } from 'vue-router';
import Router from 'vue-router';

export const createRouter = (
  config: RouterOptions,
  features: Array<(config: RouterOptions) => RouterOptions> = []
): Router => {
  let newConfig = { ...config };

  features.forEach(feature => {
    newConfig = feature(newConfig);
  });

  return new Router(newConfig);
};
