import { datadogRum } from '@datadog/browser-rum';
import { commonBeforeSend } from '@path-ai/analytics';

import { isProduction } from '@/ui-library/utils/env';
declare global {
  const __DATADOG_CLIENT_TOKEN__: string | undefined,
    __DATADOG_RUM_APPLICATION_ID__: string | undefined,
    __APP_VERSION__: string | undefined;
}

export function addDatadog(): void {
  // Used for testing datadog outside of production
  const isDataDogEnabled = localStorage.getItem('DATADOG_ENABLED');

  if (
    __DATADOG_CLIENT_TOKEN__ &&
    __DATADOG_RUM_APPLICATION_ID__ &&
    (isProduction() || isDataDogEnabled)
  ) {
    datadogRum.init({
      site: 'datadoghq.com',
      beforeSend: commonBeforeSend,
      clientToken: __DATADOG_CLIENT_TOKEN__,
      env: __DEPLOYMENT_TARGET__,
      service: 'path-ui',
      version: __APP_VERSION__,
      applicationId: __DATADOG_RUM_APPLICATION_ID__,
      sessionSampleRate: 100,
      sessionReplaySampleRate: 0, // Disallow session replay
      trackResources: true,
      trackLongTasks: true,
      trackUserInteractions: true,
      telemetrySampleRate: 0,
    });
  }
}
