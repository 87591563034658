// This composable is responsible for doing:
// - Initial user data load
// - Exposing the user data and user helpers globally
// - Verify the user is authenticated

import { datadogRum } from '@datadog/browser-rum';
import { findLast, isEmpty } from 'lodash';
import { defineStore, storeToRefs } from 'pinia';
import { computed, ref, watch } from 'vue';
import type { NavigationGuard } from 'vue-router';

import type { User } from '@/api/monocle/users';
import {
  UserGroup,
  useUserMe,
  useUserPermissionsMe,
} from '@/api/monocle/users';
import type { PermissionConfig, PermissionGroup } from '@/ui-library/constants';
import { PermissionConfigs, UserPermission } from '@/ui-library/constants';
import { getIsUnsupportedBrowser } from '@/ui-library/utils/browser';
import Routes from '@/ui-library/utils/routes';

const getPermissionsDictionary = <T extends string>(
  arr: T[]
): Record<T, boolean> =>
  arr.reduce(
    (acc, permission) => {
      acc[permission] = true;
      return acc;
    },
    {} as Record<T, boolean>
  );

/**
 * Manages loading user data and initial authentication check
 *
 * NOTE: This should only be used at the top of apps to handle checking authentication and loading the user data.
 *  All other places should use `useUser`
 */
export const useUserManager = defineStore('userManager', () => {
  // Used to know we did our first load (auth check)
  // If this is true, it is assumed the user is authenticated and
  // all required data has been loaded for the app to operate
  const initialized = ref(false);

  const {
    data: user,
    detail: getUser,
    clear: clearUser,
  } = useUserMe({ auto: false });

  const {
    data: userPermissions,
    detail: getUserPermissions,
    clear: clearUserPermissions,
  } = useUserPermissionsMe({ auto: false });

  const permissions = computed(() => {
    if (!userPermissions.value) {
      return null;
    }
    const { permissions: perms, groups } = userPermissions.value;

    return getPermissionsDictionary([...perms, ...groups]);
  });

  const permissionGroups = computed(() => {
    if (!userPermissions.value) {
      return null;
    }
    const { groups } = userPermissions.value;

    return getPermissionsDictionary(groups);
  });

  /**
   * Initialize the user manager
   *
   * This should be called at the top of the app
   * to ensure the user is authenticated, all required
   * data has been loaded and datadog user properties are set
   *
   * @returns {}
   */
  const initialize = async () => {
    if (initialized.value) {
      console.warn('User Manager already initialized.');
      return;
    }

    try {
      await Promise.all([getUser(), getUserPermissions()]);

      initialized.value = true;
    } catch (e) {
      // TODO: This should be state (route config) driven instead of being kept in sync manually
      // Start login process if getting user data failed.
      if (
        // Don't redirect to login if we're already on the login page
        !window.location.href.includes('/authentication') &&
        // Don't redirect to login if we're already on the error page
        !window.location.href.includes('/error')
      ) {
        window.location.assign(
          Routes.authentication.initiateLogin(window.location.href)
        );

        // Return something so that consumers of this function know to stop execution so that no race conditions occur with the redirect
        return { redirectingToLogin: true };
      }
    }
  };

  /**
   * Refresh all of the user data
   *
   * This is useful for:
   *  - testing
   *  - refreshing user data if it were to be updated
   */
  const refreshUserData = () => Promise.all([getUser(), getUserPermissions()]);

  /**
   * Reset the user manager to its initial state
   *
   * This is useful for:
   *  - testing
   *  - logging out a user without reloading the app (today not used that way)
   */
  const reset = () => {
    initialized.value = false;
    clearUser();
    clearUserPermissions();
  };

  // Watch user data and set/remove datadog context properties
  watch(user, val => {
    if (val) {
      // Add user information to all logs
      const {
        userId,
        userGroup,
        firstName,
        lastName,
        emailAddress,
        partnerId,
        partnerName,
      } = val;
      datadogRum.setUser({
        id: userId.toString(),
        name: `${firstName} ${lastName}`,
        email: emailAddress,
        userGroup,
        partnerId,
        partnerName,
      });
    } else {
      // Clear user
      datadogRum.clearUser();
    }
  });

  return {
    initialized,
    user,
    permissions,
    permissionGroups,
    initialize,
    refreshUserData,
    reset,
  };
});

/**
 * Safely access user data
 *
 * NOTE: This must be used within an app that has first initialized useUserManager and guarded rendering the rest of the app on it
 *
 */
export const useUser = () => {
  const {
    user: rawUser,
    permissions: rawPermissions,
    permissionGroups: rawPermissionGroups,
  } = storeToRefs(useUserManager());

  // This is a special case, we use this in places where being authenticated isn't required
  // This could also be used to safely check that we have loaded user data before accessing any other computed in this composable
  const isUserPresent = computed(() => !isEmpty(rawUser.value));

  const user = computed<User>(() => {
    if (!rawUser.value) {
      throw new Error(
        'User not defined but attempted to be access. Make sure this data is loaded before attempting to use it.'
      );
    }
    return rawUser.value;
  });

  const permissions = computed(() => {
    if (!rawPermissions.value) {
      throw new Error(
        'User Permissions not defined but attempted to be access. Make sure this data is loaded before attempting to use it.'
      );
    }
    return rawPermissions.value as Record<
      UserPermission | PermissionGroup,
      boolean
    >;
  });

  const permissionGroups = computed<Record<PermissionGroup, boolean>>(() => {
    if (!rawPermissionGroups.value) {
      throw new Error(
        'User Permission Groups not defined but attempted to be access. Make sure this data is loaded before attempting to use it.'
      );
    }
    return rawPermissionGroups.value;
  });

  // TODO: [PERMISSION_TO_GROUP] Remove the hasPermission and hasBasePerm parts
  const hasPermissionGroup = (permissionConfig: PermissionConfig) => {
    const hasPermission = (
      permissionName: UserPermission | PermissionGroup
    ): boolean => Boolean(permissions.value[permissionName]);
    const hasBasePerm = permissionConfig.permissionAnd
      ? permissionConfig.permissions.every(basePerm => hasPermission(basePerm))
      : permissionConfig.permissions.some(basePerm => hasPermission(basePerm));

    return (
      permissionConfig.groups.some(group => !!permissionGroups.value[group]) ||
      hasBasePerm
    );
  };

  const isAdmin = computed(() => user.value.userGroup === UserGroup.ADMINS);

  const isPartner = computed(() => user.value.userGroup === UserGroup.PARTNERS);

  const isContributor = computed(
    () => user.value.userGroup === UserGroup.CONTRIBUTORS
  );

  const isUserAdmin = computed(() =>
    hasPermissionGroup(PermissionConfigs.UsersManage)
  );

  const isPartnerAdmin = computed(() => {
    // Note: this list will change as permissions that constitute a partner admin change. Currently this is dupe of `isUserAdmin`
    const privileges = [
      UserPermission.ChangeUser,
      UserPermission.ChangeProjectMembership,
    ];
    return privileges.every(privilege => permissions.value[privilege]);
  });

  // TODO: [PERMISSION_TO_GROUP] Deprecate the below permission-related const ters. Can directly use user.hasPermissionGroup
  const canAnnotate = computed(() =>
    Boolean(permissions.value[UserPermission.AddAnnotation])
  );

  const canEditJob = computed(() =>
    hasPermissionGroup(PermissionConfigs.ManageJob)
  );

  const canViewJob = computed(() =>
    hasPermissionGroup(PermissionConfigs.ViewJob)
  );

  const isFeatureEnabledForUser = (featureName: string) =>
    Boolean(user.value.featureTags?.includes(featureName));

  const crossPartnerMembershipEnabled = isAdmin;

  return {
    isUserPresent,
    user,
    permissions,
    permissionGroups,
    isAdmin,
    isPartner,
    isContributor,
    isUserAdmin,
    isPartnerAdmin,
    canAnnotate,
    canEditJob,
    canViewJob,
    crossPartnerMembershipEnabled,
    hasPermissionGroup,
    isFeatureEnabledForUser,
  };
};

/**
 * Helper to handle authentication checks on route navigation and unsupported browser checks
 *
 * This is meant to be used during the an app's setup
 */
export const authAndBrowserCheckBeforeEach: NavigationGuard = async (
  to,
  _,
  next
) => {
  // Ensure the user manager is initialized
  const userStore = useUserManager();
  if (!userStore.initialized) {
    const res = await userStore.initialize();
    // user manager is redirecting, no reason to proceed farther
    if (res?.redirectingToLogin) {
      return;
    }
  }

  // Handle redirecting to the unsupported browser page
  if (
    getIsUnsupportedBrowser() &&
    to.path !== Routes.authentication.unsupportedBrowser()
  ) {
    window.location.assign(Routes.authentication.unsupportedBrowser());
    next(false);
    return;
  }
  // Finds the deepest places in the tree where
  // the user has defined authorization, use that.
  // This means that a child route's 'access' level
  // will overwrite the parents.
  const authRoute = findLast(to.matched, 'meta.access');
  // Default to true if no access level is defined.
  const isAuthenticated = authRoute?.meta?.access?.isAuthenticated ?? true;

  // Check if the user is authenticated if they need to be.
  const { isUserPresent } = useUser();
  if (isAuthenticated !== isUserPresent.value) {
    window.location.assign('/');
    next(false);
    return;
  }

  next();
};
