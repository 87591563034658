import { isDev } from '@/ui-library/utils/env';
import type { Id } from '@/utils/types';

// Pulled out for reuse
const viewerBeta = (id: Id, queryParams?: string) =>
  `/slides/view/${id}${queryParams ? `?${queryParams}` : ''}`;

export default {
  /** Authentication urls are for accounts app only */
  authentication: {
    login: (redirectUrl?: string) =>
      `/authentication${
        redirectUrl ? `?url=${encodeURIComponent(redirectUrl)}` : ''
      }`,
    unsupportedBrowser: () => '/authentication/unsupportedBrowser',
    forgotPassword: () => '/authentication/forgotPassword',
    resetPassword: () => '/authentication/resetPassword',
    newPassword: () => '/authentication/newpassword',
    register: () => '/authentication/register',
    // Error page that accounts may redirect to
    error: () => '/error',
    // Reverse-proxied to slidesapp
    logout: () => '/logout-slides',
    // Reverse-proxied to slidesapp
    initiateLogin: (url: string): string =>
      `/initiate-login${
        url
          ? `?url=${encodeURIComponent(url)}${isDev() ? '&local=true' : ''}`
          : ''
      }`,
  },
  account: {
    home: () => '/account',
    boardCertification: () => '/account/register/step2',
    identityVerification: () => '/account/register/step3',
    fundingSource: () => '/account/register/step4',
  },
  admin: {
    home: () => '/admin',
    recentLogins: () => '/admin/recentLogins',
    stats: () => '/admin/stats',
  },
  datasets: {
    manage: () => '/datasets',
    view: (id: Id) => `/datasets/view/${id}`,
    upload: () => '/datasets/upload',
  },
  candidateRuns: {
    manage: () => '/candidate-runs',
    view: (id: Id) => `/candidate-runs/${id}`,
  },
  slides: {
    home: () => '//slides.pathai.com/',
    search: () => '/search',
    // TODO: It would be nice to type the query params in order to get autocomplete, type checking and make migrations easier
    viewerBeta,
    // Jobs
    viewerBetaJobMode: (id: Id, queryParams?: string) =>
      viewerBeta(id, `job-mode=true${queryParams ? `&${queryParams}` : ''}`),
    // Frames
    viewerBetaWithFrame: (slideId: Id, frameId: Id, queryParams?: string) =>
      viewerBeta(
        slideId,
        `frameId=${frameId}${queryParams ? `&${queryParams}` : ''}`
      ),
    // Overlay review
    overlayReview: (id: Id, queryParams?: string) =>
      viewerBeta(
        id,
        `overlayReview=true${queryParams ? `&${queryParams}` : ''}`
      ),
    details: (id: Id) => `/slides/view/${id}/details`,
    uploadMetadata: () => '/uploadmetadata',
  },
  search: {
    annotations: () => '/search/annotations',
    reviewAnnotations: () => '/search/annotations/review',
    frames: () => '/search/frames',
  },
  upload: {
    home: () => '/slides/upload',
    manageKeys: () => '/slides/upload/s3',
  },
  jobs: {
    manage: () => '/jobs/manage',
    draft: () => '/jobs/draft/',
    payment: (id: Id) => `/jobs/payments/${id}`,
    edit: (id: Id) => `/editjob/${id}`,
    details: (id: Id) => `/jobs/view/${id}`,
    dashboard: () => '/job-dashboard',
  },
  overlays: {
    manage: () => '/overlays',
    manageKeys: () => '/overlays/s3',
    ingest: (id: Id) => `/overlays/ingest/${id}`,
    detail: (id: Id) => `/overlays/${id}`,
    details: (id: Id) => `/overlays/${id}`, // Details page has same path
    review: (id: Id) => `/overlays/${id}/review`,
  },
  projects: {
    home: () => '/projects',
    create: () => '/projects/create',
    edit: (id: Id) => `/projects/edit/${id}`,
    view: (id: Id) => `/projects/view/${id}`,
    overview: (id: Id) => `/projects/view/${id}`,
    users: (id: Id) => `/projects/view/${id}/users`,
    addExternalUser: (projectId: Id) =>
      `/projects/add-external-user/${projectId}`,
    report: (projectId: Id, reportId: Id) =>
      `/projects/view/${projectId}/report/${reportId}`,
    cases: (id: Id) => `/projects/view/${id}/cases`,
    overlays: (projectId: Id) => `/projects/view/${projectId}/overlays`,
    page: (projectId: Id, pageId: Id) =>
      `/projects/view/${projectId}/pages/${pageId}`,
    createPage: (id: Id) => `/projects/view/${id}/pages/create`,
    editPage: (projectId: Id, pageId: Id) =>
      `/projects/view/${projectId}/pages/edit/${pageId}`,
    slides: (id: Id) => `/search?project=${id}`,
  },
  substances: {
    manage: () => '/substances',
    add: () => '/substances/add',
    edit: (id: Id) => `/substances/edit/${id}`,
    detail: (id: Id) => `/substances/view/${id}`,
  },
  users: {
    manage: () => '/users/all',
    invite: () => '/users/invite',
    view: (id: Id) => `/users/view/${id}`,
    inviteToProject: (projectId: Id) => `/users/invite?project=${projectId}`,
    contributor: (id: Id) => `/users/contributor/${id}`,
    contributorProfiles: () => '/users/contributor-profile/search/',
    approvals: () => '/users/pending',
    tags: () => '/users/tagging',
    editTag: (id: Id) => `/users/tagging/edit/${id}`,
    addTag: () => '/users/tagging/add',
    addCategory: () => '/users/tagging/categories/add',
    editCategory: (id: Id) => `/users/tagging/categories/edit/${id}`,
    partners: () => '/users/partners',
  },
  reports: {
    registration: () => '/registrationstatus',
    datasets: () => '/datasets',
  },
  public: {
    home: () => 'https://www.pathai.com/',
    partners: () => 'https://www.pathai.com/partners/',
  },
  evaluations: {
    home: () => '/jobs/evaluations',
    evaluation: (id: Id) => `/jobs/evaluations/${id}`,
    create: () => '/jobs/evaluations/create',

    // For reference outside of jobs app
    globalHome: () => '/jobs/evaluations',
    globalCreate: () => '/jobs/evaluations/create',
  },
};
