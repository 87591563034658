import type { Maybe } from '@/utils/types';

const SESSION_KEY = 'SLIDE_SESSION_ID';

export function getSlideSessionCookie(): Maybe<string> {
  const r = document.cookie.match(`\\b${SESSION_KEY}=([^;]*)\\b`);
  return r ? r[1] : null;
}

const AUTH_HEADER_PREFIX = 'SLIDE_SESSION_ID sessionId=';

export function getAuthHeader({ quoted = true } = {}): string {
  if (quoted) {
    return `${AUTH_HEADER_PREFIX}"${getSlideSessionCookie()}"`;
  }
  return `${AUTH_HEADER_PREFIX}${getSlideSessionCookie()}`;
}
