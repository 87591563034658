import { useToastManager } from '@path-ai/anodyne';
import { defineStore } from 'pinia';

export const useToasterStore = defineStore('toaster', useToastManager);

// Simple hook to expose the global methods without exposing internals
export const useToaster = () => {
  const { addToastMessage, clearAllToasts } = useToasterStore();
  return { addToastMessage, clearAllToasts };
};
