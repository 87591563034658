import 'uno.css';
import 'normalize.css';
// This imports the CSS at the top so it can be overridden by the other CSS files
import '@path-ai/anodyne';
import '@babel/polyfill';
import 'anodyne-ui/style.css';
import '@/ui-library/style/core.scss';

import { Anodyne } from 'anodyne-ui';
import axios from 'axios';
import { createPinia, PiniaVuePlugin } from 'pinia';
import Vue from 'vue';
import VueClipboard from 'vue-clipboard2';
import type { RouterOptions } from 'vue-router';
import VueRouter from 'vue-router';
import VueShowdown from 'vue-showdown';
import Vuex from 'vuex';
import { sync } from 'vuex-router-sync';

import { authAndBrowserCheckBeforeEach } from '@/ui-library/composables/useUserManager';
import { addDatadog } from '@/ui-library/plugins/datadog';
import HelpScout from '@/ui-library/plugins/HelpScout';
import { createRouter } from '@/ui-library/router';
import withAuth from '@/ui-library/router/features/withAuth';
import type { Obj } from '@/utils/types';

import { addSentry } from '../plugins/sentry';

interface Plugins {
  routerConfig: RouterOptions;
  storeConfig?: Obj;
}
type VueContructorOptions = ConstructorParameters<typeof Vue>[0];

export const initializeApp = (
  baseConfig: VueContructorOptions,
  plugins: Plugins
) => {
  // Some sort of a store config is required so long as we are using vue-tables-2
  const { routerConfig, storeConfig = {} } = plugins;

  const vueInstance = Vue;
  vueInstance.use(PiniaVuePlugin);
  vueInstance.use(Vuex);
  vueInstance.use(VueRouter);
  vueInstance.use(Anodyne);
  vueInstance.use(VueClipboard);
  vueInstance.use(VueShowdown, {
    options: {
      emoji: true,
      tables: true,
      openLinksInNewWindow: true,
      simplifiedAutoLink: true,
      headerLevelStart: 2,
    },
  });
  vueInstance.config.productionTip = false;

  const router = createRouter(routerConfig, [withAuth]);

  HelpScout.initBeacon();
  addDatadog();
  addSentry(vueInstance);

  const config = {
    ...baseConfig,
    router,
    pinia: createPinia(),
    store: new Vuex.Store(storeConfig),
  };

  // NOTE - similar thing as mentioned above
  // @ts-expect-error - This seems to be outside the normal pattern for Vuex and we plan to refactor it in the future
  config.store.$router = config.router;
  sync(config.store, config.router);

  // Setup app wide user loading, auth check, supported browser check
  config.router.beforeEach(authAndBrowserCheckBeforeEach);

  // Disable logging unhandled rejections
  window.addEventListener('unhandledrejection', ev => {
    if (axios.isCancel(ev.reason)) {
      ev.preventDefault();
      ev.stopImmediatePropagation();
    }
  });

  return new Vue(config);
};
