import { init } from '@sentry/vue';
import type { VueConstructor } from 'vue';
import type Vue from 'vue';

import { isProduction } from '@/ui-library/utils/env';

declare global {
  const __SENTRY_DSN__: string | undefined;
}

export function addSentry(vueInstance: VueConstructor<Vue>): void {
  // Used for testing datadog outside of production
  const isSentryEnabled = localStorage.getItem('SENTRY_ENABLED');

  if (__SENTRY_DSN__ && (isProduction() || isSentryEnabled)) {
    init({
      Vue: vueInstance,
      dsn: __SENTRY_DSN__,
      release: `path-ui@${__APP_VERSION__}`,
      dist: __DEPLOYMENT_TARGET__,
      environment: __DEPLOYMENT_TARGET__,
      beforeSend: (error, hint) => {
        const { exception } = error;
        const { originalException } = hint;

        // Filter out cancelled requests
        const isCancelledRequest =
          exception?.values?.[0]?.type === 'UnhandledRejection' &&
          (originalException as any)?.message === 'canceled';

        // Filter out specific http error codes
        const errors = [401, 403, 404];
        const isExcludedErrorCode =
          (originalException as any).response?.status &&
          errors.includes((originalException as any).response?.status);

        if (isCancelledRequest || isExcludedErrorCode) {
          return null;
        }
        return error;
      },
    });
  }
}
