import bowser from 'bowser';

import type { Maybe } from '@/utils/types';

// Supported Browsers
const MINIMUM_SUPPORTED_VERSION_BY_BROWSER = {
  msie: '>=11',
  edge: '>=68',
  safari: '>=11',
  chrome: '>=68',
  firefox: '>=61',
  electron: '>=10',
};

export type DeviceType = 'tablet' | 'mobile' | 'desktop';
export interface BrowserInfo {
  deviceType: DeviceType;
  browserName: string;
  browserVersion: string;
  osName: string;
  osVersion: string;
  mobileDeviceType?: Maybe<string>;
}
export interface ScreenInfo {
  innerHeight: number;
  innerWidth: number;
  outerHeight: number;
  outerWidth: number;
  availableHeight: number;
  availableWidth: number;
}

const browser = () => bowser.getParser(window.navigator.userAgent);

export const getIsUnsupportedBrowser = () =>
  !browser().satisfies(MINIMUM_SUPPORTED_VERSION_BY_BROWSER);

const getDeviceType = (): DeviceType => {
  const deviceType = browser().getPlatformType(true);
  if (deviceType === 'tablet' || deviceType === 'mobile') {
    return deviceType;
  }
  return 'desktop';
};
const getMobileDeviceType = () => {
  const platform = browser().getPlatform();
  const mobileDevice = platform.model ? platform.model.toLowerCase() : null;
  const knownDevices = new Set(['iphone', 'ipad', 'ipod', 'touchpad']);
  if (mobileDevice && knownDevices.has(mobileDevice)) {
    return mobileDevice;
  }
  return 'unknown';
};
export const getBrowserInfo = (): BrowserInfo => {
  const browserInfo: BrowserInfo = {
    deviceType: getDeviceType(),
    browserName: browser().getBrowserName(),
    browserVersion: browser().getBrowserVersion(),
    osName: browser().getOSName(),
    osVersion: browser().getOSVersion(),
  };

  if (browserInfo.deviceType !== 'desktop') {
    browserInfo.mobileDeviceType = getMobileDeviceType();
  }
  return browserInfo;
};
export const getScreenInfo = (): ScreenInfo => ({
  innerHeight: window.innerHeight,
  innerWidth: window.innerWidth,
  outerHeight: window.outerHeight,
  outerWidth: window.outerWidth,
  availableHeight: window.screen.availHeight,
  availableWidth: window.screen.availWidth,
});
