import { datadogRum } from '@datadog/browser-rum';
import axios from 'axios';
import { mapValues } from 'lodash';
import type { Ref } from 'vue';
import { computed, isRef, ref, unref } from 'vue';
import type {
  ListApi,
  ListApiOptions,
  ParamsArg,
  RecursivePartial,
  UrlArg,
} from 'vue-restful';
import { ListApiImpl } from 'vue-restful';
import type { Jsonable, JsonableWithRefs } from 'vue-restful/dist/types';

import type { ById, Maybe } from '@/utils/types';

export function deepUnref(obj: JsonableWithRefs): Jsonable {
  if (isRef(obj)) {
    return unref(obj);
  } else if (Array.isArray(obj)) {
    return obj.map(deepUnref);
  } else if (obj && typeof obj === 'object') {
    return mapValues(obj, deepUnref);
  }
  return obj;
}

function unrefParams(params: ParamsArg): Maybe<ById<Jsonable>> {
  if (isRef(params)) {
    return unref(params);
  }
  return mapValues(params, deepUnref);
}

export interface InfiniteScrollCursorApi<T, Req = RecursivePartial<T>>
  extends ListApi<T, Req> {
  cursor: Ref<string | null>;
}

export interface InfiniteScrollCursorApiOptions<T, Res>
  extends ListApiOptions<T, Res> {
  extractResponseCursor?: (responseData: Res) => Maybe<string>;
  limit?: number | Ref<number>;
}

export class InfiniteScrollCursorApiImpl<T, Res, Req> extends ListApiImpl<
  T,
  Res,
  Req
> {
  protected limit: Ref<number>;
  protected cursor: Ref<Maybe<string>> = ref(null);
  protected extractResponseCursor: (responseData: Res) => Maybe<string>;

  constructor(
    url: UrlArg,
    {
      limit = 100,
      params = ref({}),
      extractResponseCursor,
      ...rest
    }: InfiniteScrollCursorApiOptions<T, Res> = {}
  ) {
    super(url, {
      params: computed(() => {
        const innerParams = unrefParams(params);
        return innerParams ? { ...innerParams, limit: unref(limit) } : null;
      }),
      ...rest,
    });
    this.limit = ref(limit);
    this.extractResponseCursor = extractResponseCursor || (() => null);
  }

  // Override list() to inject cursor.
  async list(): Promise<void> {
    // Only one request at a time.
    if (this.loadingCount.value > 0) {
      return;
    }

    // Don't request more if we've loaded everything.
    if (this.loaded.value && this.cursor.value === null) {
      return;
    }
    // TODO:
    // As of right now we cannot use the next url because in local development we need to reverse proxy to staging and not call it directly.
    // However, once the path-ui separation work all requests will be reverse proxied which will allow us to use the next url.
    try {
      const cursor = this.cursor.value;
      const response = await this.makeRequest({
        method: 'GET',
        url: this.getListUrl(),
        params: {
          ...this.params.value,
          cursor,
        },
      });
      const data = this.processData(response);
      this.orderedIds.value = [
        ...this.orderedIds.value,
        ...data.map(this.getId),
      ];
      const newCursor = this.extractResponseCursor(response.data);
      this.cursor.value = newCursor;
      const newCount = this.extractResponseCount(response.data);

      // If the cursor is null, we've loaded everything.
      //
      //  It is possible that the count provided in pagination is incorrect.
      //  This can happen in the case where our ES backend doesn't 100% agree with the DB.
      //  In this case we are going to update the count to be the number of items we have loaded
      //  and log an error in datadog.
      const resultsCount = this.orderedIds.value.length;
      if (newCursor === null && newCount !== resultsCount) {
        datadogRum.addError(
          {
            type: 'InfiniteScrollCursorApiImpl',
            message: 'InfiniteScrollCursorApiImpl: counts do not match',
          },
          {
            url: this.getListUrl(),
            params: this.params.value,
            expectedCount: newCount,
            returnedCount: resultsCount,
          }
        );
        this.count.value = resultsCount;
      } else {
        this.count.value = newCount;
      }

      this.loaded.value = true;
    } catch (e) {
      if (axios.isCancel(e)) {
        return;
      }
      throw e;
    }
  }

  clear(): void {
    super.clear();
    this.cursor.value = null;
  }

  bind(): InfiniteScrollCursorApi<T, Req> {
    return {
      ...super.bind(),
      cursor: computed(() => this.cursor.value),
    };
  }
}

export function useInfiniteScrollCursorApi<T, Res, Req = RecursivePartial<T>>(
  url: UrlArg,
  options?: InfiniteScrollCursorApiOptions<T, Res>
): InfiniteScrollCursorApi<T, Req> {
  const api = new InfiniteScrollCursorApiImpl(url, options);
  api.initialize();
  return api.bind();
}
