import type { RouteConfig, RouterOptions } from 'vue-router';

type Route = RouteConfig & {
  isAuthenticated?: boolean;
};
const buildRoute = (route: Route): RouteConfig => {
  const { isAuthenticated } = route;

  const newRoute = {
    ...route,
  };

  // Route level access has been configured
  // We don't want to add metadata for a route that does not have auth
  // configured, as we uses the deepest access configuration in the tree
  if (isAuthenticated !== undefined) {
    newRoute.meta = {
      ...newRoute.meta,
      access: {
        isAuthenticated: isAuthenticated !== undefined ? isAuthenticated : true,
      },
    };

    delete newRoute.isAuthenticated;
  }

  // If the current route is a leaf, return
  if (!route.children || route.children.length === 0) {
    return newRoute;
  }

  // Otherwise recurse
  return {
    ...newRoute,
    children: newRoute.children?.map(child => buildRoute(child)),
  };
};

const withAuth = (config: RouterOptions): RouterOptions => ({
  ...config,
  routes: config.routes?.map(route => buildRoute(route)),
});

export default withAuth;
