import { MONOCLE_BASE_PATH } from '@/ui-library/utils/monocleHttp';
import type { Id } from '@/utils/types';

export const admin = {
  getRecentLogins: () => `${MONOCLE_BASE_PATH}/logins/`,
  getBasicStats: () => `${MONOCLE_BASE_PATH}/basic-stats/`,
};

export const annotations = {
  annotationById: (id: Id) =>
    `${MONOCLE_BASE_PATH}/annotations/annotations/${id}/`,
  annotations: () => `${MONOCLE_BASE_PATH}/annotations/annotations/`,
  reviewAnnotation: () => `${MONOCLE_BASE_PATH}/annotations/status/`,
  bulkReviewAnnotation: () => `${MONOCLE_BASE_PATH}/annotations/status/bulk/`,
  unreviewAnnotation: (id: Id) =>
    `${MONOCLE_BASE_PATH}/annotations/status/${id}/`,
  substances: () => `${MONOCLE_BASE_PATH}/annotations/substances/`,
};

export const assignments = {
  getAssignmentsForJob: (jobId: Id) =>
    `${MONOCLE_BASE_PATH}/jobs/jobs/${jobId}/contributors/`,
  deleteAssignmentFromJob: (jobId: Id, assignmentId: Id) =>
    `${MONOCLE_BASE_PATH}/jobs/jobs/${jobId}/contributors/${assignmentId}/`,
  assignments: () => `${MONOCLE_BASE_PATH}/assignments/assignments/`,
  assignmentById: (assignmentId: Id) =>
    `${MONOCLE_BASE_PATH}/assignments/assignments/${assignmentId}/`,
  framesForJob: (jobId: Id) =>
    `${MONOCLE_BASE_PATH}/jobs/jobs/${jobId}/frames/`,
  slidesForJob: (jobId: Id) =>
    `${MONOCLE_BASE_PATH}/jobs/jobs/${jobId}/slides/`,
};

export const authentication = {
  getCSRFToken: () => '/csrfToken',
  getInvitationInfo: (invitationToken: Id) =>
    `/invitationinfo/${invitationToken}`,
  postSAMLLoginInfo: () => '/samllogin',
  postAuthenticate: () => '/authenticate',
  postRequestReset: () => '/requestreset',
  postSetPassword: () => '/setPassword',
  postSubmitRegistration: () => '/submitRegistration',
  postOnFidoRegistration: () =>
    `${MONOCLE_BASE_PATH}/onfido-onboarding/onfido-registration/`,
  postCheckIdentity: () =>
    `${MONOCLE_BASE_PATH}/onfido-onboarding/create-identity-check/`,
};

export const collaboration = {
  getComments: () => `${MONOCLE_BASE_PATH}/comments/`,
  patchComment: (commentId: Id) =>
    `${MONOCLE_BASE_PATH}/comments/${commentId}/`,
  postComment: () => `${MONOCLE_BASE_PATH}/comments/`,
};

export const contributor = {
  postSendHelpEmail: () => '/rpc/sendHelpEmailContributor',
};

export const datasets = {
  getRegistrationStatusByDataset: () =>
    `${MONOCLE_BASE_PATH}/datasets-by-registration-status/`,
  postExportDatasetSheets: (datasetId: Id) =>
    `${MONOCLE_BASE_PATH}/datasetsheets/${datasetId}/export/`,
  postImportDatasetSheet: (datasetId: Id) =>
    `${MONOCLE_BASE_PATH}/datasetsheets/${datasetId}/import/`,
};

export const jobs = {
  getJob: (jobId: Id) => `/rpc/jobs/${jobId}`,
  getJobsFromMonocle: () => `${MONOCLE_BASE_PATH}/jobs/jobs/`,
  getJobById: (jobId: Id) => `${MONOCLE_BASE_PATH}/jobs/jobs/${jobId}/`,
  getJobOverlaysFromMonocle: (jobId: Id) =>
    `${MONOCLE_BASE_PATH}/jobs/jobs/${jobId}/runs/`,
  getJobConstraints: () => '/rpc/jobs/constraints',
  getJobContributors: () => '/rpc/jobs/contributors',
  getContributorSlideAssignments: () => '/rpc/jobs/slideAssignments',
  getJobHeatmaps: () => '/rpc/jobs/heatmaps',
  postJobPayment: (jobId: Id) => `/rpc/jobs/${jobId}/pay`,
};

export const overlays = {
  runsMonocle: () => `${MONOCLE_BASE_PATH}/runs/`,
  runMonocle: (runId: Id) => `${MONOCLE_BASE_PATH}/runs/${runId}/`,
};

export const partners = {
  getPartnersMonocle: () => `${MONOCLE_BASE_PATH}/partners/`,
  getProjectsMonocle: () => `${MONOCLE_BASE_PATH}/project-management-projects/`,
};

export const projects = {
  getProjectsMonocle: () => `${MONOCLE_BASE_PATH}/project-management-projects/`,
  postProject: () => `${MONOCLE_BASE_PATH}/create-project/`, // Consolidation endpoint - will accept reports and stages in the payload
  getProject: (projectId: Id) =>
    `${MONOCLE_BASE_PATH}/project-management-projects/${projectId}/`,
  patchProject: (projectId: Id) =>
    `${MONOCLE_BASE_PATH}/project-management-projects/${projectId}/`,
  patchStage: (stageId: Id) => `${MONOCLE_BASE_PATH}/stages/${stageId}/`,
  getProjectReport: (projectId: Id) =>
    `${MONOCLE_BASE_PATH}/project-management-projects/${projectId}/report/`,
  postProjectReport: () => `${MONOCLE_BASE_PATH}/reports/`,
  patchProjectReport: (reportId: Id) =>
    `${MONOCLE_BASE_PATH}/reports/${reportId}/`,
  deleteProjectReport: (reportId: Id) =>
    `${MONOCLE_BASE_PATH}/reports/${reportId}/`,
  getProjectPages: (projectId: Id) =>
    `${MONOCLE_BASE_PATH}/project-management-projects/${projectId}/pages/`,
  postProjectPage: (projectId: Id) =>
    `${MONOCLE_BASE_PATH}/project-management-projects/${projectId}/pages/`,
  getProjectPage: (projectId: Id, pageId: Id) =>
    `${MONOCLE_BASE_PATH}/project-management-projects/${projectId}/pages/${pageId}/`,
  patchProjectPage: (projectId: Id, pageId: Id) =>
    `${MONOCLE_BASE_PATH}/project-management-projects/${projectId}/pages/${pageId}/`,
  postProjectPageImage: (projectId: Id, pageId: Id) =>
    `${MONOCLE_BASE_PATH}/project-management-projects/${projectId}/pages/${pageId}/images/`,
  getProjectPageImages: (projectId: Id, pageId: Id) =>
    `${MONOCLE_BASE_PATH}/project-management-projects/${projectId}/pages/${pageId}/images/`,
  getSlideGroups: () => `${MONOCLE_BASE_PATH}/slide-groups/`,
  getStains: () => `${MONOCLE_BASE_PATH}/stains/`,
  getDiseases: () => `${MONOCLE_BASE_PATH}/diseases/`,
  postModelCodePreview: () => `${MONOCLE_BASE_PATH}/ml-models/preview/`,

  // Not used (yet)
  // getProjectActivity: (projectId: Id) => `${MONOCLE_BASE_PATH}/project-management-projects/${projectId}/activity/`,
};

export const reporting = {
  getVisualizationBySlug: (visualizationSlug: Id) =>
    `${MONOCLE_BASE_PATH}/visualization-types/${visualizationSlug}/`,
  getGBEMetadata: (visualizationDataId: Id) =>
    `${MONOCLE_BASE_PATH}/visualization-data/${visualizationDataId}/gbe_metadata/`,
  getGBEFeatures: (visualizationDataId: Id) =>
    `${MONOCLE_BASE_PATH}/visualization-data/${visualizationDataId}/gbe_features/`,
  getVisualizationData: (visualizationDataId: Id) =>
    `${MONOCLE_BASE_PATH}/visualization-data/${visualizationDataId}/gbe_dashboard/`,
  getVisualizationDataId: () => `${MONOCLE_BASE_PATH}/visualization-data/`,
};

export const questions = {
  getQuestions: () => '/rpc/questions',
  getJobQuestions: () => '/rpc/jobs/questions',
  getQuestion: (questionId: Id) => `/rpc/questions/${questionId}`,
  getQuestionOptions: () => '/questions/options',
  postQuestion: () => '/rpc/questions',
  postQuestionOption: () => '/questions/options',
  postQuestionResponse: (questionId: Id) =>
    `/questions/${questionId}/responses`,
  putQuestion: (questionId: Id) => `/rpc/questions/${questionId}`,
  putQuestionResponse: (questionId: Id, responseId: Id) =>
    `/questions/${questionId}/responses/${responseId}`,
};

export const ratings = {
  categories: () => `${MONOCLE_BASE_PATH}/ratings/categories/`,
  ratings: (categoryId: Id) =>
    `${MONOCLE_BASE_PATH}/ratings/categories/${categoryId}/ratings/`,
  rates: () => `${MONOCLE_BASE_PATH}/ratings/ratings/`,
};

export const registration = {
  AWSUser: () => `${MONOCLE_BASE_PATH}/upload/upload-user`,
};

export const slides = {
  getSlideFrames: (slideId: Id) => `/rpc/getSlideFrames/${slideId}`,
  getSlideQuestionResponses: () => '/questions/responses',
  getSlideBasics: (slideId: Id) =>
    `${MONOCLE_BASE_PATH}/slides/${slideId}/basics/`,
};

export const substances = {
  getSubstanceById: (substanceId: Id) =>
    `${MONOCLE_BASE_PATH}/substances/${substanceId}/`,
  getSubstancesFromMonocle: () => `${MONOCLE_BASE_PATH}/substances/`,
};

export const tagging = {
  tags: () => `${MONOCLE_BASE_PATH}/tagging/tags/`,
  tagById: (tagId: Id) => `${MONOCLE_BASE_PATH}/tagging/tags/${tagId}/`,
  categories: () => `${MONOCLE_BASE_PATH}/tagging/categories/`,
  categoryById: (categoryId: Id) =>
    `${MONOCLE_BASE_PATH}/tagging/categories/${categoryId}/`,
};

export const user = {
  getUser: () => '/user',
  getUserProjects: () => `${MONOCLE_BASE_PATH}/user/project-membership/`,
  getUserPermissions: () => `${MONOCLE_BASE_PATH}/user/permissions/`,
  getUserContributorProfile: () => '/user/contributorProfile',
  getAbmsProfiles: () => '/rpc/searchABMS',
  postAbmsProfile: () => '/rpc/contributors/abms',
};

export const users = {
  getUsers: () => `${MONOCLE_BASE_PATH}/users/`,
  userById: (userId: Id) => `${MONOCLE_BASE_PATH}/users/${userId}/`,
  userProjectsById: (userId: Id) =>
    `${MONOCLE_BASE_PATH}/users/${userId}/project-membership/`,
  getGroups: () => `${MONOCLE_BASE_PATH}/groups/`,
  getContributorProfile: (userId: Id) => `/rpc/contributor/${userId}`,
};

export default {
  admin,
  annotations,
  authentication,
  collaboration,
  contributor,
  datasets,
  jobs,
  overlays,
  partners,
  projects,
  questions,
  ratings,
  registration,
  slides,
  substances,
  tagging,
  user,
  users,
};
