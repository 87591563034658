import type { AxiosRequestConfig } from 'axios';
import { computed, unref } from 'vue';
import type { EndpointApi, EndpointApiOptions, IdArg } from 'vue-restful';

import type {
  MonocleEndpointData,
  MonocleListData,
  MonocleSelectData,
} from '@/api/monocle/composables';
import {
  getSelectId,
  useMonocleDetailFactory,
  useMonocleEndpoint,
  useMonocleEndpointFactory,
  useMonocleListFactory,
} from '@/api/monocle/composables';
import { DEFAULT_RATING_CATEGORY } from '@/api/monocle/ratings';
import type { Tag } from '@/api/monocle/tags';
import type { PermissionGroup, UserPermission } from '@/ui-library/constants';
import { monocle } from '@/ui-library/utils/monocleHttp';
import { npt } from '@/utils/strings';
import type { Id, Maybe } from '@/utils/types';

export enum UserGroup {
  ADMINS = 'ADMINS',
  PARTNERS = 'PARTNERS',
  CONTRIBUTORS = 'CONTRIBUTORS',
}

export interface User {
  userId: number;
  firstName: string;
  lastName: string;
  emailAddress: string;
  userGroup: Maybe<UserGroup>;
  partnerId: Maybe<number>;
  partnerName: Maybe<string>;
  dateAwsCredsCreated: Maybe<string>;
  featureTags: string[];
  // No longer in use in the app.
  hasProjectAccess: boolean;
}

export interface UserList {
  userId: number;
  firstName: string;
  lastName: string;
  emailAddress: string;
  userGroup: {
    userGroupId: number;
    name: UserGroup;
    stormpathName: string;
    invitationsAllowed: boolean;
    passwordRuleSet: Maybe<number>;
  };
  username: string;
  partnerId: Maybe<number>;
  partnerName: Maybe<string>;
  dateAwsCredsCreated: Maybe<string>;
  canAnnotate: boolean;
  featureTags: string[];
  partner: Maybe<{
    partnerId: number;
    shortName: string;
    longName: string;
    userInviteDomainRestriction: boolean;
  }>;
  dateRegistered: string;
  active: boolean;
  tags: number[];
  groups: Array<{
    id: number;
    name: string;
    priorityKey: number;
    scope: string;
  }>;
  isOnboarded: boolean;
  invitedByUserId: Maybe<number>;
  invitationEmailAddress: Maybe<string>;
  invitationUserGroup: {
    userGroupId: number;
    name: UserGroup;
    stormpathName: string;
    invitationsAllowed: boolean;
    passwordRuleSet: Maybe<number>;
  };
  newUserApprovalStatus: 'Approved' | 'PendingApproval' | 'NotApproved';
  lastLogin: string;
}

export interface UserPermissions {
  permissions: UserPermission[];
  groups: PermissionGroup[];
}

export interface UserDetail {
  userId: number;
  firstName?: Maybe<string>;
  lastName?: Maybe<string>;
  emailAddress?: Maybe<string>;
  dateAwsCredsCreated?: Maybe<string>;
  canAnnotate?: boolean;
  invitedByUserId?: number;
  abmsCredentialingStatus?: Maybe<string>;
  identityCredentialingStatus?: Maybe<string>;
  lastLogin?: Maybe<string>;
  dateRegistered?: Maybe<string>;
  fundingSource?: Array<{
    id: number;
    bankName: string;
    isActive: boolean;
    name: string;
    status: 'verified' | 'unverified' | 'removed';
    createdAt: string;
  }>;
  tags?: Tag[];
  userGroup?: {
    invitationAllowed: boolean;
    name: string;
    passwordRuleSet: null;
    stormpathName: string;
    userGroupId: number;
  };
  contributorProfile?: Maybe<Id>;
  isOnboarded: boolean;
  newUserApprovalStatus: 'Approved' | 'PendingApproval' | 'NotApproved';
  invitationEmailAddress: Maybe<string>;
  contributorOnboarded: boolean;
  active: boolean;
  partner: Maybe<{
    partnerId: number;
    shortName: string;
    longName: string;
    userInviteDomainRestriction: boolean;
  }>;
  roles: Array<{
    id: number;
    name: string;
    priorityKey: number;
    scope: string;
  }>;
}

export interface NestedUser {
  userId: number;
  firstName: string;
  lastName: string;
  emailAddress: string;
  userGroup: Maybe<number>;
}

interface PartnerRole {
  name: string;
  partner: string;
}

interface ProjectRole {
  id: number;
  name: string;
  project: string;
  partner: string;
}

interface UserRole {
  name: string;
  id: number;
  scope: string;
}

interface UserOption extends MonocleSelectData {
  partnerName: Maybe<string>;
}

export interface UserRow {
  userId: number;
  fullName: string;
  displayEmail: string;
  isInternal: boolean;
  status: 'Active' | 'Pending' | 'Deactivated';
  roles: UserRole[];
  partnerRoles: PartnerRole[];
  projectRoles: ProjectRole[];
  lastLogin: Maybe<string>;
  organization: string;
  projectMembership: Maybe<number>;
}

export const USER_URL = '/user/';
export const USERS_URL = '/users/';
export const PENDING_USERS_URL = '/users/pending-approval/';
export const PENDING_USER_APPROVE_URL = (id: number) =>
  `/users/${id}/approve-invitation/`;
export const PENDING_USER_DECLINE_URL = (id: number) =>
  `/users/${id}/decline-invitation/`;
const USER_OPTIONS_URL = '/users/select-options/';
export const USER_PERMISSIONS_URL = '/user/permissions/';

const getId = (x: { userId: number }): number => x.userId;

// Load the possibly logged in user
export const useUserMe = (
  options?: EndpointApiOptions<User, MonocleEndpointData<User>>
) => useMonocleEndpoint<User>(USER_URL, options);

export const useUserPermissionsMe = (
  options?: EndpointApiOptions<
    UserPermissions,
    MonocleEndpointData<UserPermissions>
  >
) => useMonocleEndpoint<UserPermissions>(USER_PERMISSIONS_URL, options);

export const useUserList = useMonocleListFactory<UserList>(USERS_URL, {
  getId,
});

export const useUserOptionList = useMonocleListFactory<UserOption>(
  USER_OPTIONS_URL,
  { getId: getSelectId, extractResponseCount: x => x.data.length }
);

// Used for filters TODO: Migrate away from using this for filtering or have the backend support fullName by default
export type UserWithFullname = UserList & { fullName: string };
export const useUserWithFullnameList = useMonocleListFactory<
  UserWithFullname,
  MonocleListData<UserList>
>(USERS_URL, {
  getId,
  extractResponseData: res =>
    res.data.map<UserWithFullname>((user: UserList) => ({
      ...user,
      fullName: `${user.firstName} ${user.lastName}`,
    })),
});

export const useUserDetail = useMonocleDetailFactory<UserDetail>(USERS_URL);

export function useDeactivateReason(userId: IdArg): EndpointApi<string[]> {
  return useMonocleEndpoint(
    computed(() => npt`${USERS_URL}${unref(userId)}/deactivate_reasons/`)
  );
}

/*
  Interfaces for role management.
*/

export interface Role {
  name: string;
  id: number;
}

/*
  These types define a two-layer-deep tree showing every permission
  associated to a user.
  * The top layer contains information about roles assigned to the
    user, including both GLOBAL and SELF.
  * The middle layer contains information about partners - either
    those to which the user has a membership or merely a cross-partner
    membership to a project within.
  * The lowest layer lists information about project memberships.
  The types are displayed here from smallest to greatest.
*/

export interface ProjectPermissionDetails {
  projectName: string;
  projectId: string;
  roles: Role[];
}

export interface PartnerPermissionDetails {
  // Note: This only implies a partner membership if is_member is true!
  // Otherwise, it's just a partner to which the user has a cross-partner
  // project membership.
  partnerName: string;
  partnerId: number;
  roles: Role[];
  isMember: boolean;
  projects: ProjectPermissionDetails[];
}

export interface UserPermissionDetails {
  globalRoles?: Role[];
  contributorRoles?: Role[];
  involvedWithPartners: PartnerPermissionDetails[];
}

/*
  The different kinds of layers in the tree above are PermissionLevels,
  enumerated here
*/

export enum PermissionLevel {
  Global = 'global',
  Partner = 'partner',
  Project = 'project',
  Contributor = 'contributor',
}

/*
  A 'row' of the tree (or literally a row of permission table) consists
  of a level, alongside a specification of what partner or project that
  permission corresponds to.

  The `PermissionScope` tagged union specifies some location at which it
  is possible to assign permissions.
*/

interface GlobalPermissionScope {
  level: PermissionLevel.Global;
}
interface PartnerPermissionScope {
  level: PermissionLevel.Partner;
  partner: number;
}
interface ProjectPermissionScope {
  level: PermissionLevel.Project;
  project: string;
}
interface ContributorPermissionScope {
  level: PermissionLevel.Contributor;
}
export type PermissionScope =
  | GlobalPermissionScope
  | PartnerPermissionScope
  | ProjectPermissionScope
  | ContributorPermissionScope;

/*
  To represent updates, we require a list of new roles and a location
  at which to assign them; this is captured in PermissionUpdate
*/

interface RolesSpecification {
  roles: Role[];
}
export type PermissionUpdate = PermissionScope & RolesSpecification;

/*
  A helper which updates permission details based on an update. Returns a boolean indicating
  success; can only edit existing rows!
*/

export function applyPermissionUpdateToDetails(
  details: UserPermissionDetails,
  update: PermissionUpdate
): boolean {
  switch (update.level) {
    case PermissionLevel.Global: {
      details.globalRoles = update.roles;
      return true;
    }
    case PermissionLevel.Partner: {
      for (const partner of details.involvedWithPartners) {
        if (partner.partnerId === update.partner) {
          partner.roles = update.roles;
          return true;
        }
      }
      return false;
    }
    case PermissionLevel.Project: {
      for (const partner of details.involvedWithPartners) {
        for (const project of partner.projects) {
          if (project.projectId === update.project) {
            project.roles = update.roles;
            return true;
          }
        }
      }
      return false;
    }
    case PermissionLevel.Contributor: {
      details.contributorRoles = update.roles;
      return true;
    }
  }
}

// API utilities for handling information about permissions.
export const useUserDetailedPermissions = (
  userId: IdArg,
  options?: EndpointApiOptions<
    UserPermissionDetails,
    MonocleEndpointData<UserPermissionDetails>
  >
) =>
  useMonocleEndpoint<UserPermissionDetails>(
    computed(() => npt`${USERS_URL}${unref(userId)}/detailed_permissions/`),
    options
  );

export async function setRolesForLevel(
  userId: number,
  permissionUpdate: PermissionUpdate
) {
  return monocle.patch<string>(`${USERS_URL}${userId}/update_roles/`, {
    ...permissionUpdate,
    roles: permissionUpdate.roles.map(role => role.id),
  });
}

// API utilities for loading information about AuthRoles.

export interface AuthRole {
  id: number;
  name: string;
  scope: string;
  priorityKey: number;
}
const AUTH_ROLES_LIST = '/auth-roles/';

export const useAuthRoles = useMonocleListFactory<AuthRole>(AUTH_ROLES_LIST, {
  getId: role => role.id,
});
export const useUserTableList = useMonocleListFactory<UserRow>('/user-table/', {
  getId: user => user.userId,
});
export const getUserTable = (config: AxiosRequestConfig) =>
  monocle.get<MonocleListData<UserRow>>('/user-table/', config);

export const deleteProjectMembership = (
  userId: IdArg,
  projectMembershipId: IdArg
) =>
  monocle.delete<null>(
    `/users/${userId}/project-membership/${projectMembershipId}/`
  );

// Enums to express the invitable user groups; the back-end uses different terminology than
// we want to display to users, so we also include a utility for mapping between the two
// possible terminologies.
export enum InvitableUserGroup {
  Admins = 'ADMINS',
  Partners = 'PARTNERS',
  Contributors = 'CONTRIBUTORS',
}
export enum InvitableUserGroupDisplayName {
  Internal = 'Internal',
  External = 'External',
  Contributor = 'Contributor',
}
export function userGroupFromDisplayName(
  displayName: InvitableUserGroupDisplayName
): InvitableUserGroup {
  switch (displayName) {
    case InvitableUserGroupDisplayName.Internal:
      return InvitableUserGroup.Admins;
    case InvitableUserGroupDisplayName.External:
      return InvitableUserGroup.Partners;
    case InvitableUserGroupDisplayName.Contributor:
      return InvitableUserGroup.Contributors;
  }
}

export interface InvitationBody {
  emailAddress: string;
  invitationGroup: InvitableUserGroup;
  roleAssignments: PermissionUpdate[];
  partner?: number;
  bypassAbms?: boolean;
  bypassBank?: boolean;
}

const INVITATION_URL_MAP = {
  [InvitableUserGroup.Admins]: `${USERS_URL}invite_admin/`,
  [InvitableUserGroup.Partners]: `${USERS_URL}invite_partner/`,
  [InvitableUserGroup.Contributors]: `${USERS_URL}invite_contributor/`,
};

export async function sendInvitation(body: InvitationBody) {
  // Change representation of Roles to just their primary key.
  const transformedBody = {
    ...body,
    roleAssignments: body.roleAssignments.map(permissionUpdate => ({
      ...permissionUpdate,
      roles: permissionUpdate.roles.map(role => role.id),
    })),
  };
  // Compute where to send this request based on the user group.
  const url = INVITATION_URL_MAP[body.invitationGroup];
  return monocle.post<MonocleEndpointData<UserList>>(url, transformedBody, {
    // For now: this is needed for the request interceptor to snake case the keys of
    // the object. (This is harmless, but ought to be fixed when monocle axios is)
    headers: { ['Content-Type']: 'application/json' },
  });
}

export const remindInvitation = (userId: IdArg) =>
  monocle.patch<{ data: UserDetail }>(
    `${USERS_URL}${userId}/remind_invitation/`
  );

export const onboardContributor = (userId: IdArg) =>
  monocle.patch<null>(`${USERS_URL}${userId}/onboard_contributor/`);

export const toggleUserActiveState = (
  userId: IdArg,
  payload: {
    active: boolean;
    reason?: string;
    detail?: string;
  }
) => {
  const { active, reason, detail } = payload;
  monocle.patch(`${USERS_URL}${userId}/`, {
    active,
    deactivateReason: active ? undefined : reason,
    deactivateReasonDetail: active ? undefined : detail,
  });
};

export interface UserRating {
  id: Maybe<number>;
  user: number;
  category: {
    id: number;
    name: string;
    defaultRating: Maybe<number>;
    isDefault: boolean;
  };
  rating: {
    name: string;
    isDefault: boolean;
    quota: number;
    id: number;
    pk: number;
  };
  timestamp: string;
  isDefault: boolean;
}

export const useUserRating = (
  userId: IdArg,
  options?: EndpointApiOptions<UserRating, MonocleEndpointData<UserRating>>
) =>
  useMonocleEndpoint<UserRating>(
    computed(
      () => npt`${USERS_URL}${unref(userId)}/rating/${DEFAULT_RATING_CATEGORY}/`
    ),
    options
  );

export interface UserPendingApproval {
  userId: number;
  emailAddress: string;
  inviterName: string;
  partnerName: Maybe<string>;
  groupName: string;
  invitationDate: string;
}
export const useUserPendingApprovals =
  useMonocleEndpointFactory<UserPendingApproval[]>(PENDING_USERS_URL);
export const approveUserInvitation = (id: number) =>
  monocle.post(PENDING_USER_APPROVE_URL(id));
export const declineUserInvitation = (id: number) =>
  monocle.post(PENDING_USER_DECLINE_URL(id));
