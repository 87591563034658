import type { Maybe } from '@/utils/types';

/**
 * NPT stands for null passthrough, a tagged function to return null if any of
 * its args are null.
 *
 *   npt`a${"b"}c` === 'abc'
 *   npt`a${null}c` === null // not 'anullc'
 */
export function npt(
  strings: TemplateStringsArray,
  ...args: unknown[]
): Maybe<string> {
  if (args.some(arg => arg == null)) {
    return null;
  }
  return String.raw(strings, ...args);
}
