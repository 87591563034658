import type { Maybe } from 'vue-restful';

import {
  useMonocleListFactory,
  useMonocleSelectListFactory,
} from '@/api/monocle/composables';
import type { UserRating } from '@/api/monocle/users';
import { monocle } from '@/ui-library/utils/monocleHttp';

export const DEFAULT_RATING_CATEGORY = 1;

interface Category {
  defaultRating: Maybe<number>;
  id: number;
  isDefault: boolean;
  name: string;
}

interface Rating {
  id: number;
  isDefault: boolean;
  name: string;
  pk: number;
  quota: number;
}

interface ContributorRating {
  category: Category;
  id: number;
  isDefault: boolean;
  rating: Rating;
  timestamp: string;
  user: number;
}

export const useContributorRatingList =
  useMonocleListFactory<ContributorRating>(
    `ratings/categories/${DEFAULT_RATING_CATEGORY}/ratings/`,
    {
      getId: x => x.user,
    }
  );

export const useRatingSelect = useMonocleSelectListFactory(
  '/ratings/ratings/select/'
);

export const updateUserRating = (userId: number, ratingId: number) =>
  monocle.post<UserRating>(
    `/ratings/categories/${DEFAULT_RATING_CATEGORY}/ratings/`,
    {
      user: userId,
      rating: ratingId,
    }
  );
